const testimonialsData = [
  {
    text: `I used to spend hours every day comparing 
    different vendor pricing on items, stock levels and availability.  
    The time it takes to calculate and place orders 
    has been shortened and so has the stress.  Thank you DGI for your service!`,
    author: "Sharon",
    position: "Owner",
    shopName: "UME Custom Embroidery & Imprinting",
    location: "Fort Lupton, CO",
  },
 {
  text:`I no longer visit each individual vendor to place my blank garment orders - I rely completely on DGI.  
  It saves me time (lots of time) and money. DGI has changed the industry.`,
  author: "Kerry",
  position: "Owner",
  shopName: "Big Frog of the Upper West Side",
  location: "New York, NY"
},
{
  text:`An amazing site and time-saver!`,
  author: "Laura",
  position: "Owner",
  shopName: "Green Tee Promos",
  location: "Longmont, CO"
},
{
  text:`These guys are awesome and this platform is an awesome training tool and purchasing tool!! Keep it up fellas 👊🏻👊🏻`,
  author: "Ed",
  position: "Operations Manager",
  shopName: "Thread Mob Custom Apparel",
  location: "Columbus, GA"
},
{
  text:`It's so amazing and our ordering person has been singing praises all week. So awesome!!`,
  author: "Richard",
  position: "Owner",
  shopName: "Imagination Athletics",
  location: "Conway, SC"
},
{
  text:`Absolutely LOVE this platform for my ordering needs! This has cut our ordering time in half and makes it super easy to train new hires on ordering! Thanks DGI :)`,
  author: "Kelsey",
  position: "Store Manager",
  shopName: "CBJ Custom Apparel",
  location: "Sulphur Springs, TX"
},
{
  text:`Big time saver and money saver. I consider DGI a must have if you are purchasing apparel from multiple vendors.`,
  author: "Jeff",
  position: "Owner",
  shopName: "Big Frog of San Marcos",
  location: "San Marcos, CA"
},
{
  text:`DGI offers a concise and well put together solution for a problem that I had previously thought was unavoidable. Major props to the team for identifying a weak spot in our industry, designing a well made solution, AND OFFERING IT FOR FREE.`,
  author: "Jordan",
  position: "Account Manager",
  shopName: "Danger Press",
  location: "Atlanta, GA"
},
{
  text:`This platform is amazing! If you are using the major vendors and not using this tool, you are missing a great opportunity!`,
  author: "Gail",
  position: "Owner",
  shopName: "Big Frog of Shorewood",
  location: "Shorewood, IL"
},
{
  text:`DGI Apparel has transformed how we order blank apparel, and quote product. It's a game changer!`,
  author: "Scott",
  position: "Owner",
  shopName: "King Screen",
  location: "Roanoke, VA"
},
];
export default testimonialsData;
