import React, { useEffect } from "react";

import styles from "./styles.module.scss";
import {
  Button,
  TableRow,
  TableCell,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { PriceRowText } from "custom/text/shopping_page/StandardProductCardTexts";
import VendorLogo from "custom/img/VendorLogo";
import { formatPrice } from "utilities/formatPrice";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { LOADING_STATES } from "features/productsSlice";
import { useSelector } from "react-redux";
import { checkIfSaleExists } from "utilities/parseInventoryData";
import { useNavigate } from "react-router-dom";

const ProductPriceRow = React.memo(function ProductPriceRow({
  dataKey,
  vendorCode,
  dgi_style_sku,
  vendorName,
  setOpenInvDetails,
  setSelectedVendorKey,
  masterColor,
  setVendorProducts,
  productStyle,
  isConnectionRow,
  hidePrices
}) {
  const navigate = useNavigate();
  const vendorProduct = useSelector(
    (state) =>
      state.products?.products?.[dgi_style_sku + vendorCode + masterColor]
  );
  const productPrice = vendorProduct?.data?.product_price;
  const cutoffIndicatorColor = vendorProduct?.data?.cutoff_indicator;
  const cutoffWhse = vendorProduct?.data?.cutoff_whse;
  const loadingPrice = vendorProduct?.loading === LOADING_STATES.LOADING_STATUS;
  const priceFetched =
    vendorProduct?.loading === LOADING_STATES.SUCCESS_STATUS ||
    vendorProduct?.loading === LOADING_STATES.FAILURE_STATUS;
  const orderingTimeLeft = vendorProduct?.data?.remaining_order_time
    ? Math.round(vendorProduct.data.remaining_order_time)
    : "?";
  const saleExists = checkIfSaleExists(vendorProduct?.data?.sized_pricing_data);

  useEffect(() => {
    if (vendorProduct) {
      setVendorProducts((prevVendorProducts) => ({
        ...prevVendorProducts,
        [dgi_style_sku + vendorCode + masterColor]: vendorProduct,
      }));
    }
  }, [vendorProduct]);

  if (!dataKey) {
    return null;
  }

  const navigateToIntegrationsPage = () => {
    navigate(`/integrations?vendor=${vendorCode}`)
  }

  const onRowClick = () => {
    if (productPrice || isConnectionRow) {
      setSelectedVendorKey(dataKey);
      setOpenInvDetails(true);
    }
  };

  let renderPrice = null;
  if (hidePrices) {
    renderPrice = null;
  } else if (loadingPrice && !productPrice) {
    // no price in state
    renderPrice = <CircularProgress size="1.2rem" />;
  } else if (priceFetched || productPrice) {
    // updated price OR stale price in state
    renderPrice = (
      <PriceRowText
        content={formatPrice(productPrice ?? "Color N/A")}
        price={true}
        loading={loadingPrice}
      />
    );
  }

  let cutoffTooltip;
  if (cutoffIndicatorColor === "green") {
    const timeLeft =
      orderingTimeLeft > 1 ? `${orderingTimeLeft} hours` : `hour`;
    cutoffTooltip = `${cutoffWhse} is still accepting orders for the next ${timeLeft}`;
  } else if (cutoffIndicatorColor === "orange") {
    const timeLeft =
      orderingTimeLeft > 1 ? `${orderingTimeLeft} minutes` : `minute`;
    cutoffTooltip = `${cutoffWhse} will stop accepting orders in ${timeLeft}`;
  } else if (cutoffIndicatorColor === "red") {
    cutoffTooltip = `${cutoffWhse} has stopped accepting orders today`;
  } else if (cutoffIndicatorColor === "gray") {
    cutoffTooltip = "Drop shipping orders ship tomorrow";
  }

  const tooltipBoxShadow =
    "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)";
  const renderCutoff = !loadingPrice && productPrice && (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            background: "#f9f9f9",
            opacity: "0.6",
            fontSize: "14px",
            color: "#111",
            marginTop: "6px !important",
            boxShadow: tooltipBoxShadow,
            border: "1px solid darkgray",
          },
        },
        arrow: {
          sx: {
            color: "#f9f9f9",
            borderBottom: "none",
            "::before": {
              border: "1px solid darkgray",
            },
          },
        },
      }}
      title={cutoffTooltip}
      arrow={true}
    >
      <AccessTimeIcon
        sx={{
          color: cutoffIndicatorColor,
          width: "0.85em",
          height: "0.85em",
        }}
      />
    </Tooltip>
  );

  const renderSale = !loadingPrice && saleExists && (
    <div
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              background: "#f9f9f9",
              opacity: "0.6",
              fontSize: "14px",
              color: "#111",
              marginTop: "6px !important",
              boxShadow:
                "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
              border: "1px solid darkgray",
            },
          },
          arrow: {
            sx: {
              color: "#f9f9f9",
              borderBottom: "none",
              "::before": {
                border: "1px solid darkgray",
              },
            },
          },
        }}
        title={`The ${productStyle} is currently on sale at ${vendorName}`}
        arrow
      >
        <LocalOfferIcon sx={{ fontSize: "1.1rem", color: "#5bd4c0" }} />
      </Tooltip>
    </div>
  );

  if (isConnectionRow) {
    return (
      <TableRow
        id={`${vendorName}-${productStyle}-connection-row`}
        onClick={onRowClick}
        className={styles.productPriceRow}
      >
        <TableCell
          align="left"
          className="w-[140px] pl-[8px] pr-[0px] py-0 sm:w-[38%] vendorLogoCell border-none"
        >
          <VendorLogo vendorDataKey={dataKey} />
        </TableCell>
        <TableCell
          align="left"
          className="w-[80px] vendorRowCell sm:w-[25%] productStyleCell"
        >
          <PriceRowText content={productStyle} />
        </TableCell>
        <TableCell
          className={`w-[40px] 
            vendorRowCell 
            md:w-[25%] 
            hidden sm:table-cell`}
        ></TableCell>
        <TableCell
          align="right"
          className={`w-[98px] 
            py-0 pl-0 pr-[10px] 
            renderPriceCell border-none`}
        >
          <Button
            className="bg-blueblue hover:bg-denim text-grayscaleWhite normal-case py-[2px] px-[6px]"
            align="right"
            onClick={() => navigateToIntegrationsPage()}
          >
            Connect
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow
      id={`${vendorName}-${productStyle}-price-row`}
      className={styles.productPriceRow}
      onClick={onRowClick}
    >
      <TableCell
        align="left"
        className="w-[140px] pl-[8px] pr-[0px] py-0 sm:w-[38%] vendorLogoCell border-none"
      >
        <VendorLogo vendorDataKey={dataKey} />
      </TableCell>
      <TableCell
        align="left"
        className="w-[80px] vendorRowCell sm:w-[25%] productStyleCell"
      >
        <PriceRowText content={productStyle} />
      </TableCell>
      <TableCell
        className={`w-[40px] 
          vendorRowCell 
          md:w-[17%] 
          hidden sm:table-cell`}
      >
        <div className="flex items-center w-full">
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {renderCutoff}
          </div>
          {renderSale}
        </div>
      </TableCell>
      <TableCell
        align="right"
        className={`w-[98px] 
          py-0 pl-0 pr-[14px] 
          renderPriceCell border-none`}
      >
        {renderPrice}
      </TableCell>
    </TableRow>
  );
});

export default ProductPriceRow;
