import React from "react";

import styles from "./styles.module.scss";
import ConnectionInfo from "./ConnectionInfo";
import ConnectionModules from "./ConnectionModules";

export default ({}) => {
  return (
    <div className={styles.integrationsPageBackground}>
      <div className={styles.connectionPage}>
        <div className="flex flex-col items-center">
          <h1 className="text-4xl mt-[0px] font-normal">Which accounts would you like to connect?</h1>
          <h2 className="text-3xl mt-[0px] pb-[1em] font-normal">Connect all of your vendors below</h2>
        </div>
        <div className={styles.connectionPageContent}>
          <ConnectionModules />
          <ConnectionInfo />
        </div>
      </div>
    </div>
  );
};
