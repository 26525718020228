import { IconButton, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

export default function WelcomeSection({ scrollToHowItWorks }) {
  const displayGetStartedButton = useMediaQuery("(min-width:1280px)"); // tailwind lg breakpoint
  const navigate = useNavigate();

  return (
    <div className={styles.welcomeContainer}>
      <div>
        <div className={styles.welcomeText}>
          <div>
            <span>Purchasing for your custom apparel shop?</span>
          </div>
          <div>
            <span>Find the best deals on one website.</span>
          </div>
        </div>
      </div>
      <div className={styles.welcomeSubText}>
        <p>
          See <span className="font-bold">your custom pricing</span> across all vendors and cut ordering time in half. Completely free for apparel shops & non-profits.
        </p>
      </div>
      <div
        className={`
        ${styles.infoButtonWrapper} 
        ${
          !displayGetStartedButton &&
          `flex flex-col items-center justify-center
        gap-[15px]`
        }`}
      >
        <Button
          id="how-it-works-btn"
          className={styles.infoButton}
          onClick={scrollToHowItWorks}
        >
          How it works
        </Button>
        <IconButton
          className={`
            ${styles.infoButton}
            flex-row items-center gap-[12px]`}
          data-get-started={true}
          onClick={() => navigate("/auth")}
        >
          <Typography sx={{ color: "rgb(25, 118, 210)" }}>
            Get Started
          </Typography>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}
