import React, { useState, useEffect, useRef } from "react";
import ColorSwatch from "items/ColorSwatch";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Collapse,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import { markMatchedText } from "utilities/markText";

const ColorOption = ({ color, colorInSearchBox }) => {
  const { dgiStyle, colorQuery } = useParams();
  const navigate = useNavigate();
  const isSelectedColor = color.toLowerCase() === colorQuery.toLowerCase();

  const onColorChange = () => {
    const encodedColor = encodeURIComponent(color);
    // Replace allows us to navigate back to the previous page without going
    // through every selected color
    navigate(`/product/${dgiStyle}/${encodedColor}`, { replace: true });
  };

  const markedText = markMatchedText(color, colorInSearchBox);

  return (
    <Box
      className={`flex justify-start items-center 
        flex-shrink-0 flex-grow-0 w-[130px]
        gap-[4px] cursor-pointer rounded-soft`}
      sx={{
        "&:hover": {
          borderColor: !isSelectedColor && "rgba(0, 0, 0, 0.2)",
        },
        border: isSelectedColor
          ? "1px solid rgba(0, 0, 0, 0.8)"
          : "1px solid transparent",
      }}
      onClick={onColorChange}
    >
      <ColorSwatch
        id={`${dgiStyle}-${color}-select-btn`}
        key={`${dgiStyle}-${color}-select-btn`}
        dgiStyle={dgiStyle}
        color={color}
        hasSwatchText={true}
        disabled={true}
      />
      <div>
        <span className="font-light text-almostSM">{markedText}</span>
      </div>
    </Box>
  );
};

const ColorSelector = ({
  filteredColorNames,
  colorInSearchBox,
  setColorInSearchBox,
}) => {
  const { colorQuery } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(true);
  const colorsPrompt = expanded ? "Hide colors" : "See all colors";
  const collapseRef = useRef(null);

  useEffect(() => {
    const checkHeight = () => {
      const collapseHeight = collapseRef.current.scrollHeight;
      // This is really screwy. I'm getting different values on staging and main
      // Needs deeper investigation and no use effect
      setShowExpandButton(collapseHeight >= 150); // 150 is the collapsed size
    };

    checkHeight();

    // Recheck height on window resize
    window.addEventListener("resize", checkHeight);
    return () => window.removeEventListener("resize", checkHeight);
  }, [filteredColorNames.length, collapseRef?.current?.scrollHeight]);

  const handleColorSearchChange = (e) => {
    setColorInSearchBox(e.target.value);
  };

  const renderNoColorsMessage = filteredColorNames.length === 0 && (
    <div
      className={`
        flex justify-start items-center 
        w-full h-full ml-[10px] mt-[10px]`}
    >
      <Typography className="text-base">
        No matching colors. Try searching for another color.
      </Typography>
    </div>
  );

  return (
    <Box className="flex flex-col gap-[8px] w-full">
      <Typography variant="h6">
        <span className="text-lg">Color: </span>
        <span className="text-lg font-light">{colorQuery}</span>
      </Typography>
      <TextField
        onFocus={() => setExpanded(true)}
        onBlur={() => setExpanded(false)}
        autoComplete="off"
        placeholder="Search by color name..."
        value={colorInSearchBox}
        onChange={handleColorSearchChange}
        className="mr-[50px] mt-[5px] mb-[10px]"
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            boxShadow: "inset 0px -2px 4px rgba(0, 0, 0, 0.1)", // give bottom of textfield some depth
          },
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "rgb(2 129 192)", // lighterblueblue
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgb(2 129 192)", // lighterblueblue
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon className="text-grayscaleSmoke mr-[6px]" />
          ),
        }}
      />
      <Collapse in={expanded} collapsedSize={150}>
        <Box className="flex flex-wrap gap-[2px]" ref={collapseRef}>
          {filteredColorNames.map((c, index) => (
            <ColorOption
              color={c}
              key={index}
              colorInSearchBox={colorInSearchBox}
            />
          ))}
          {renderNoColorsMessage}
        </Box>
      </Collapse>

      {showExpandButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "65px",
            paddingTop: "15px",
          }}
        >
          <IconButton
            onClick={() => setExpanded(!expanded)}
            sx={{
              color: "rgb(2 129 192)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid rgb(2 129 192)",
              borderRadius: "5px",
              gap: "5px",
            }}
            className="hover:bg-grayscaleSilver"
          >
            {expanded ? <RemoveIcon /> : <AddIcon />}
            <Typography className="text-xl" sx={{ color: "inherit" }}>
              {colorsPrompt}
            </Typography>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ColorSelector;
