import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import ColorSelector from "./ColorSelector";
import InventoryContent from "./InventoryContent";
import ProductPageSampleAd from "./ProductPageSampleAd";
import ProductPageSimilarAd from "./ProductPageSimilarAd";

import { useDataContext, useUserStateContext } from "App";
import { useProductContext } from "./ProductPage";
import { useDispatch, useSelector } from "react-redux";
import { selectStyle } from "features/styleSlice";
import {
  LOADING_STATES,
  fetchProductStartBatch,
  fetchProductFailure,
} from "features/productsSlice";
import {
  vendorPayloadFields,
  colorFields,
  getConnectedVendors,
  getExistingProductKeys,
  createSocketPayload,
  sendPayload,
} from "features/useSocketPayloadSender";

const ColorInventoryGroup = ({
  selectedVendorKey,
  setSelectedVendorKey,
  displayVendors,
  displaySuggestedVendors,
  vendorProducts,
  dgiStyle,
  colorQuery,
  sendJsonMessage,
}) => {
  const {
    colorObj,
    colorNames,
    brand,
  } = useProductContext(); // Add styleNumber to destructuring
  const { userCreds } = useUserStateContext();
  const [loadingTableData, setLoadingTableData] = useState(false);
  const dispatch = useDispatch();
  const styleData = useSelector((state) => selectStyle(state, dgiStyle));

  const showOnlySyncedVendors = useSelector(
    (state) => state.user?.user?.show_only_synced_vendors
  );
  const [colorInSearchBox, setColorInSearchBox] = useState("");
  const { connectedVendorCodes } = useDataContext();

  const productKeys = [{ dgi_style_sku: dgiStyle, master_color: colorQuery }];
  const connectedVendors = getConnectedVendors(connectedVendorCodes);

  useEffect(() => {
    // fetching all relevant vendors' inventory data
    (async () => {
      if (colorObj) {
        const getMasterColor = (styleData) => {
          return styleData?.colors.find((color) => color?.name === colorQuery);
        }
        const existingProductKeys = getExistingProductKeys(
          dispatch,
          productKeys,
          connectedVendors
        );
        const userId = userCreds?.cognitoID;
        const payload = createSocketPayload(
          dispatch,
          userId,
          existingProductKeys,
          [styleData],
          connectedVendors,
          getMasterColor,
        );
        sendPayload(sendJsonMessage, payload);
      }
    })();
  }, [userCreds, colorObj, dgiStyle]); // TODO: do i need to add other fields to this?

  // recreating a field we use in Product that is then passed to InventoryDetails
  let areDataKeysOrdered = false;
  if (displayVendors.length > 0) {
    function checkAllDataFetched() {
      const existingDataKeys = Object.keys(vendorProducts);
      for (const vendor of displayVendors) {
        const vendorDataKey = vendor[0];
        const vendorProductKey = dgiStyle + vendorDataKey + colorObj?.name;
        if (
          !existingDataKeys.includes(vendorProductKey) ||
          (vendorProductKey[vendorDataKey]?.loading !==
            LOADING_STATES.SUCCESS_STATUS &&
            vendorProductKey[vendorDataKey]?.loading !==
              LOADING_STATES.FAILURE_STATUS)
        ) {
          return false;
        }
      }
      return true;
    }
    areDataKeysOrdered = checkAllDataFetched();
  }

  // recreating a field we use in Product that is then passed to InventoryDetails
  let vendorDataKeys = [];
  if (displayVendors.length > 0) {
    const suggestedAndConnectedVendors = displayVendors.concat(
      displaySuggestedVendors
    );
    const allRelevantVendors = showOnlySyncedVendors
      ? displayVendors
      : suggestedAndConnectedVendors;
    vendorDataKeys = allRelevantVendors.map((vendorData) => {
      const vendorDataKey = vendorData[0];
      return vendorDataKey;
    });
  }

  const adStyleNumber = styleData?.ad_style
  // This check is very important as we do not want to count ad impressions
  // when there is no ad content to load.
  const shouldDisplaySimilarAd = adStyleNumber !== "";
  const filteredColorNames = colorNames.filter((colorName) => {
    if (!colorInSearchBox) {
      return true;
    }
    return colorName.toLowerCase().includes(colorInSearchBox.toLowerCase());
  });

  return (
    <Box width="100%" className="flex flex-col items-start gap-[2rem]">
      {brand === "Allmade" && (
        <Box width="100%" paddingLeft="2rem">
          <ProductPageSampleAd originalStyleData={styleData} colorQuery={colorQuery} />
        </Box>
      )}
      {shouldDisplaySimilarAd && (
        <Box className="flex justify-center w-full">
          <ProductPageSimilarAd
            originalStyleData={styleData}
            origialProductColor={colorQuery}
            sendJsonMessage={sendJsonMessage}
          />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        gap="0rem"
      >
        <ColorSelector
          filteredColorNames={filteredColorNames}
          colorInSearchBox={colorInSearchBox}
          setColorInSearchBox={setColorInSearchBox}
        />
      </Box>
      <InventoryContent
        vendorDataKeys={vendorDataKeys}
        areDataKeysOrdered={areDataKeysOrdered}
        colorObj={colorObj}
        loadingTableData={loadingTableData}
        setLoadingTableData={setLoadingTableData}
        selectedVendorKey={selectedVendorKey}
        setSelectedVendorKey={setSelectedVendorKey}
        vendorProducts={vendorProducts}
      />
    </Box>
  );
};

export default ColorInventoryGroup;
