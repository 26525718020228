import React, { useEffect, useState, useRef } from "react";

import styles from "./styles.module.scss";
import { Typography, TextField, Link, Stack, Dialog } from "@mui/material";
import { MonetizationOn } from "@mui/icons-material";
import { ShoppingCart } from "@mui/icons-material";
import { Warehouse } from "@mui/icons-material";
import { PointOfSale } from "@mui/icons-material";
import { ErrorOutline } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "../axios/prod_client.js";
import ssClient from "../axios/ssClient.js";
import endpoints from "../axios/endpoints.js";
import { vendors, checkoutDisabledVendorNames } from "../constants/vendors";
import { debounce } from "lodash";
import { usePostHog } from "posthog-js/react";
import { fetchUserSuccess } from "features/userSlice";
import { useDispatch } from "react-redux";

import { useUserStateContext, useDataContext } from "../App";

function AccountCreationPrompt({ accountCreationLink }) {
  return (
    <div className="flex gap-[4px] pt-[14px]">
      <Typography className="text-charcoal">Don't have an account?</Typography>
      <Link
        id={accountCreationLink}
        className="cursor-pointer no-underline text-denim"
        sx={{
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        href={accountCreationLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Create a wholesale account
      </Link>
    </div>
  );
}

function DialogVendorContent({ vendorInfo }) {
  if (checkoutDisabledVendorNames.has(vendorInfo.name)) {
    return (
      <>
        <Typography variant="h6" align="center" sx={{ mt: 2, mb: 1 }}>
          Connecting your {vendorInfo.name} account will enable:
        </Typography>
        <Stack direction="column" spacing={1} alignItems="flex-start">
          <Typography
            variant="body1"
            style={{
              color: "black",
              paddingLeft: "16px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MonetizationOn
              style={{
                fontSize: "1.25rem",
                marginRight: "8px",
                color: "#45bd47",
              }}
            />
            <span>Your negotiated & sale pricing</span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "black",
              paddingLeft: "16px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Warehouse
              style={{
                fontSize: "1.25rem",
                marginRight: "8px",
                color: "#45bd47",
              }}
            />
            <span>Live inventory from all warehouses</span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "black",
              paddingLeft: "16px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ShoppingCart
              style={{
                fontSize: "1.25rem",
                marginRight: "8px",
                color: "#45bd47",
              }}
            />
            <span>Cart management</span>
          </Typography>
        </Stack>
        <AccountCreationPrompt
          accountCreationLink={vendorInfo?.account_creation_link}
        />
      </>
    );
  }
  return (
    <>
      <Typography variant="h6" align="center" sx={{ mt: 2, mb: 1 }}>
        Connecting your {vendorInfo.name} account will enable:
      </Typography>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MonetizationOn
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Your negotiated & sale pricing</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Warehouse
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Live inventory from all warehouses</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ShoppingCart
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Cart management</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PointOfSale
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Checkout with your existing billing methods</span>
        </Typography>
      </Stack>
      <AccountCreationPrompt
        accountCreationLink={vendorInfo?.account_creation_link}
      />
    </>
  );
}

const MAX_ATTEMPTS_BEFORE_WARNING = 2;

export default function LoginDialog({
  open,
  onClose,
  vendorInfo,
  vendorLogoSrc,
  resetPasswordLink,
}) {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [failedConnectionAlertType, setFailedConnectionAlertType] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [usernameBorder, setUsernameBorder] = useState(true);
  const [passwordBorder, setPasswordBorder] = useState(true);
  const loginAttemptsRef = useRef(0);
  const posthog = usePostHog();
  const { userCreds } = useUserStateContext();
  const { setConnectedVendorCodes } = useDataContext();
  const [focused, setFocused] = useState(false);
  const focusedRef = useRef(focused);
  const loginCredsRef = useRef({
    username: username,
    password: password,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => onClose(), 1500);
    }
  }, [showSuccessAlert]);

  useEffect(() => {
    focusedRef.current = focused;
  }, [focused]);

  useEffect(() => {
    loginCredsRef.current = {
      username: username,
      password: password,
    };
  }, [password, username]);

  const onConnect = (e) => {
    e.stopPropagation();

    if (e.key === "Enter" && focusedRef.current) {
      if (loginCredsRef.current.username != "" && loginCredsRef.current.password != "") {
        connectDistributor(loginCredsRef);
      }
    }
  };

  const onConnectClick = (e) => {
    e.stopPropagation();
    if (loginCredsRef.current.username != "" && loginCredsRef.current.password != "") {
      connectDistributor(loginCredsRef);
    }
  };

  const connectDistributor = async (loginCredsRef) => {
    setLoading(true);

    try {
      // Set client
      var client;
      if (vendorInfo.code === "ss") {
        client = ssClient;
      } else {
        client = axios;
      }
      const connectionResp = await client.put(
        endpoints.users(userCreds.cognitoID),
        {
          code: vendorInfo.code,
          username: loginCredsRef.current.username,
          password: loginCredsRef.current.password,
          free_shipping_minimum: vendorInfo?.freeShippingMin,
        }
      );
      setLoading(false);
      // Determine success or error UI
      if (connectionResp.status === 200) {
        setShowSuccessAlert(true);
        window.rdt("track", "Custom", { customEventName: "vendorConnected" });
      } else {
        setFailedConnectionAlertType("error");
      }
      // Update user obj + connected vendor codes in state
      const distrData = connectionResp.data.Distributors;
      let newConnectedCodes = {};
      const distrCodes = Object.keys(distrData);
      const vendorEntries = Object.entries(vendors);

      for (const entry of vendorEntries) {
        const [dataKey, vendorDataFields] = entry;
        const vendorCode = vendorDataFields.code;
        if (distrCodes.includes(vendorCode)) {
          newConnectedCodes[dataKey] = vendorCode;
        } else {
          newConnectedCodes[dataKey] = false;
        }
      }
      dispatch(fetchUserSuccess({ userObj: connectionResp.data }));
      setConnectedVendorCodes(newConnectedCodes);
      posthog?.people?.set({
        connectedVendors: true,
      });
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if (loginAttemptsRef.current > MAX_ATTEMPTS_BEFORE_WARNING) {
        setFailedConnectionAlertType("warning");
      } else {
        loginAttemptsRef.current = loginAttemptsRef.current + 1;
        setFailedConnectionAlertType("error");
      }
    }
  };

  const debounceConnect = debounce(onConnect, 100);

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      debounceConnect(e);
    });

    return () => {
      window.removeEventListener("keypress", (e) => {
        debounceConnect(e);
      });
    };
  }, []);

  const resetState = () => {
    setUsername("");
    setPassword("");
    setShowPassword(false);
    setFailedConnectionAlertType(null);
    setShowSuccessAlert(false);
    loginAttemptsRef.current = 0;
  };

  const onDialogClose = () => {
    resetState();
    onClose();
  };

  let failedConnectionAlert = null;
  if (failedConnectionAlertType === "error") {
    failedConnectionAlert = (
      <Alert className={styles.alertMessage} severity="error">
        {`Sorry! We couldn't verify your credentials with ${vendorInfo.name}. 
            Try checking your spelling and/or spacing.`}
      </Alert>
    );
  } else if (failedConnectionAlertType === "warning") {
    failedConnectionAlert = (
      <Alert className={styles.alertMessage} severity="warning">
        {`Multiple failed attempts may result in a password reset from ${vendorInfo.name}. 
      Double check your credentials/spelling before entering.`}
      </Alert>
    );
  }

  const successAlert = (
    <Alert className={styles.alertMessage} severity="success">
      Successfully uploaded your credentials!
    </Alert>
  );

  const connectVendorButton = loading ? (
    <LinearProgress className={styles.saveLoginProgress} />
  ) : (
    <Button
      className={`bg-blueblue hover:bg-denim text-grayscaleWhite px-[12px]`}
      onClick={onConnectClick}
    >
      Connect Vendor
    </Button>
  );

  const renderControl = showSuccessAlert ? (
    successAlert
  ) : (
    <>
      {failedConnectionAlert}
      {connectVendorButton}
    </>
  );

  const handleUsernameBlur = () => {
    setUsernameBorder(true);
    setFocused(false);
  };

  const handleUsernameFocus = () => {
    setUsernameBorder(false);
    setFocused(true);
  };

  const handlePasswordBlur = () => {
    setPasswordBorder(true);
    setFocused(false);
  };

  const handlePasswordFocus = () => {
    setPasswordBorder(false);
    setFocused(true);
  };

  return (
    <Dialog
      onClose={onDialogClose}
      maxWidth="xlarge"
      open={open}
      classes={{
        container: {
          maxWidth: "100vw",
        },
      }}
    >
      <div className={styles.distributorLogin}>
        <Stack
          direction="column"
          alignItems="center"
          spacing={1}
          sx={{ padding: "0 0.75rem 0.75rem 0.75rem", position: "relative" }}
        >
          <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
            <div
              className={styles.loginLogoWrapper}
              data-downsize={vendorInfo.code === "slc"}
            >
              <img src={vendorLogoSrc} alt={vendorInfo.name} />
            </div>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ position: "absolute", top: "0rem", right: "1rem" }}
          >
            <IconButton onClick={onDialogClose}>
              <Close />
            </IconButton>
          </Stack>
          <DialogVendorContent vendorInfo={vendorInfo} />
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          spacing={2}
          sx={{ padding: "0 1rem 0.5rem 1rem" }}
        >
          <TextField
            id={`${vendorInfo.name}-username-input`}
            className={styles.loginField}
            data-border={!Boolean(username) && usernameBorder}
            onBlur={handleUsernameBlur}
            onFocus={handleUsernameFocus}
            label={`${vendorInfo.name} Username`}
            placeholder="Enter your username..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            id={`${vendorInfo.name}-password-input`}
            className={styles.loginField}
            data-border={!Boolean(password) && passwordBorder}
            onBlur={handlePasswordBlur}
            onFocus={handlePasswordFocus}
            placeholder="Enter your password..."
            label={`${vendorInfo.name} Password`}
            type={showPassword ? "text" : "password"}
            value={password}
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff className={styles.passwordIcon} />
                    ) : (
                      <Visibility className={styles.passwordIcon} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          spacing={1}
          sx={{ padding: "0 1rem 1rem 1rem" }}
        >
          <a
            href={resetPasswordLink}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-blueblue ${styles.resetPasswordLink}`}
          >
            Forgot your {vendorInfo.name} password?
          </a>
          {renderControl}
          <p style={{ fontSize: "0.9rem" }}>
            All credentials are secured using 256 bit end-to-end encryption.
          </p>
          {/* Commented out the popular vendor highlight
          <Typography
            variant="body1"
            align="center"
            style={{ marginTop: '16px', color: 'black' }}
          >
          <Star 
            style={{ 
              verticalAlign: '-7px', 
              marginRight: '4px', 
              color: '#6acc6c',
              fontSize: '28px'
            }} 
          />          
            This is a popular vendor! <span style={{ color: '#6acc6c', fontWeight: '500', fontSize: '1.1em' }}>382</span> shops have synced with {vendorInfo.name}.
          </Typography>
          */}
          {/* New disclaimer */}
          <Typography
            variant="body2"
            align="center"
            style={{
              marginTop: "16px",
              color: "gray",
              fontSize: "0.8rem",
            }}
          >
            <ErrorOutline
              sx={{
                fontSize: "0.89rem",
                marginRight: "4px",
                verticalAlign: "bottom",
                marginBottom: "3px",
              }}
            />
            Treat this like logging in on {vendorInfo.name}'s website. Multiple
            failed attempts may result in a password reset from the vendor.
            Double check your credentials/spelling before entering. You will
            only need to do this process on DGI one time.
          </Typography>
        </Stack>
      </div>
    </Dialog>
  );
}
