import styles from "./styles.module.scss";
import { useState } from "react";
import { Box } from "@mui/material";

export default ({ }) => {
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const content0 = (
    <Box
      sx={{
        overflow: 'hidden',
        pt: '56.25%',
        position: 'relative',
        width: '100%',
        marginBottom: '2rem',
        '& iframe': {
          border: 0,
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        },
      }}
    >
      <iframe
        src="https://www.youtube.com/embed/KxmQxwyCczc"
        title="YouTube video player"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </Box>
  );

  const content1 =
    "When you add a vendor account, you’ll be able to see your shop’s prices, what’s in stock, active promotions, and order history while using DGI.";
  const shortContent1 = `${content1.split(" ").slice(0, 22).join(" ")}...`;

  const content2 =
    "Since there is no such standard pricing from any apparel distributor, adding your account to DGI allows you to see your shop's own pricing while using DGI, including special pricing from your reps and buying group (GPO) discounts. Adding your account also ensures you’re authorized to view information from a given vendor.";
  const shortContent2 = `${content2.split(" ").slice(0, 22).join(" ")}...`;

  const content3 =
    "Yes. We take security very seriously at DGI. All credentials are secured via 256 bit SSL encryption during transmission and storage, using a random salt for added security. The entire DGI team can never see your passwords for any vendor. DGI’s founding engineers earned degrees in computer science at MIT and have extensive training in internet security.";
  const shortContent3 = `${content3.split(" ").slice(0, 22).join(" ")}...`;

  const content4 =
    "Yes, it sure is! If your pricing is supported by a vendor’s own website, it will also be supported by DGI. This includes any buying group (GPO) pricing supported through your distributor, as well as special pricing provided by your vendor sales representative. Pricing on DGI will always match a vendor’s website.";
  const shortContent4 = `${content4.split(" ").slice(0, 22).join(" ")}...`;

  const content5 =
    "You’ll need online account access for each vendor you add to DGI. If you have an open account with the vendor, but currently order by phone only, you can ask your representative for online access. They should be able to supply it for you promptly. If you’ve just gained online access, be sure you’ve logged into the vendor’s website at least once to complete any remaining setup steps before adding the account to DGI.";
  const shortContent5 = `${content5.split(" ").slice(0, 22).join(" ")}...`;

  const content6 =
    "In almost all cases, if your account doesn’t connect, it means that either your username or password is incorrect. Please double and triple check that your credentials are accurate. However, if you're still having issues, please contact us via email at ian@dgiapparel.com or call at +1(919)-495-7693.";
  const shortContent6 = `${content6.split(" ").slice(0, 22).join(" ")}...`;

  const toggleContent1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleContent2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const toggleContent3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  const toggleContent4 = () => {
    setIsExpanded4(!isExpanded4);
  };

  const toggleContent5 = () => {
    setIsExpanded5(!isExpanded5);
  };

  const toggleContent6 = () => {
    setIsExpanded6(!isExpanded6);
  };

  return (
    <div className={`${styles.connectionInfo} ${isHovered ? styles.expanded : ''}`}>
      <h3 className="mt-0">How does DGI work?</h3>
      <p>
        <span>
          {content0}
        </span>
      </p>
      <h3>What happens when I add an account?</h3>
      <p>
        {isExpanded1 ? content1 : shortContent1}{" "}
        <span onClick={toggleContent1} className={styles.more}>
          {isExpanded1 ? "Show Less" : "Show More"}
        </span>
      </p>
      <h3>Why do I need to add my vendor accounts?</h3>
      <p>
        {isExpanded2 ? content2 : shortContent2}{" "}
        <span onClick={toggleContent2} className={styles.more}>
          {isExpanded2 ? "Show Less" : "Show More"}
        </span>
      </p>
      <h3>Is it safe to add my accounts?</h3>
      <p>
        {isExpanded3 ? content3 : shortContent3}{" "}
        <span onClick={toggleContent3} className={styles.more}>
          {isExpanded3 ? "Show Less" : "Show More"}
        </span>
      </p>
      <h3>Is my special or buying group (GPO) pricing supported?</h3>
      <p>
        {isExpanded4 ? content4 : shortContent4}{" "}
        <span onClick={toggleContent4} className={styles.more}>
          {isExpanded4 ? "Show Less" : "Show More"}
        </span>
      </p>
      <h3>Do I need login information to add my vendor?</h3>
      <p>
        {isExpanded5 ? content5 : shortContent5}{" "}
        <span onClick={toggleContent5} className={styles.more}>
          {isExpanded5 ? "Show Less" : "Show More"}
        </span>
      </p>
      <h3>DGI Apparel is saying my credentials are incorrect?</h3>
      <p>
        {isExpanded6 ? content6 : shortContent6}{" "}
        <span onClick={toggleContent6} className={styles.more}>
          {isExpanded6 ? "Show Less" : "Show More"}
        </span>
      </p>
    </div>
  );
};
