import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";

import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { useUserStateContext, useDataContext, useCheckoutContext } from "../App";

import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";

export default function ProfileMenu() {
  const navigate = useNavigate();
  const {
    signOut,
    setIsUserAuthed,
    userCreds,
    setUserCreds,
    setHasUserZip
  } = useUserStateContext();
  const { setConnectedVendorCodes } = useDataContext();
  const { setCheckoutFormData, setCanConfirmOrder } = useCheckoutContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const profileRef = useRef(null);
  const posthog = usePostHog();
  const dispatch = useDispatch();

  const navigateToTerms = () => {
    handleClose();
    navigate("/terms");
  };

  const navigateToIntegrations = () => {
    handleClose();
    navigate("/integrations");
  };

  const navigateToShopping = () => {
    handleClose();
    navigate("/shop");
  };

  const navigateToCart = () => {
    handleClose();
    navigate("/cart");
  };

  const handleClick = (event) => {
    if (profileRef.current && profileRef.current.contains(event.target)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetStateOnSignOut = () => {
    // below represents the minimum we state we must reset
    // to avoid showing data from another user

    // resetting app data
    setConnectedVendorCodes({
      ab_data: null,
      cm_data: null,
      sm_data: null,
      ss_data: null,
      as_data: null,
      acc_data: null,
      pg_data: null,
    });
    setCheckoutFormData({
      shippingAddress: null,
      warehouseShippingMethods: [],
      paymentMethod: null,
      poNumber: "",
      whsePickupTime: null,
      whsePickupDate: null
    });
    setCanConfirmOrder(false);

    // resetting user data
    setUserCreds({
      shopName: null,
      cognitoID: null,
    });
    setIsUserAuthed(false);
    setHasUserZip(null);
  }

  const signOutUser = async () => {
    dispatch({ type: 'WEBSOCKET_DISCONNECT' });
    handleClose();
    await signOut();
    resetStateOnSignOut();
    posthog?.reset();
    navigate("/");
    window.location.reload()
  };

  return (
    <div className={styles.profileMenuWrapper}>
      <Stack
        id="profile-menu-dropdown"
        direction="row"
        alignItems="center"
        onClick={handleClick}
        ref={profileRef}
      >
        <Box className="flex items-center pt-[2px] cursor-pointer hover:opacity-80">
          <Box className="hidden md:block padding-8px">
            <span className="text-white whitespace-nowrap">
              {userCreds.shopName}
            </span>
          </Box>
          <IconButton disableRipple className="text-white pt-[6px]">
            <ArrowDropDownIcon className="w-1.5R h-1.5R text-grayscaleWhite" />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            root: "z-1400"
          }}
        >
          <MenuItem
            id="shoppping-page-menu-link"
            key="shopping_page"
            className="block lg:hidden"
            onClick={navigateToShopping}
          >
            Shop
          </MenuItem>
          <MenuItem
            id="cart-page-menu-link"
            key="cart_page"
            className="block lg:hidden"
            onClick={navigateToCart}
          >
            My Carts
          </MenuItem>
          <MenuItem
            id="cart-page-menu-link"
            key="cart_page"
            className="block lg:hidden"
            onClick={() => {
              handleClose();
              navigate('/order-history')
            }}
          >
            My Order History
          </MenuItem>
          <MenuItem
            id="connections-page-menu-link"
            key="connect_vendors"
            onClick={navigateToIntegrations}
          >
            Connect Vendors
          </MenuItem>
          <MenuItem id="logout-menu-link" key="logout" onClick={signOutUser}>
            Logout
          </MenuItem>
        </Menu>
      </Stack>
    </div>
  );
}
