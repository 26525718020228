import React from "react";

import styles from "./styles.module.scss";
import DummyConnectionSlot from "./DummyConnectionSlot";

const dummyApparelVendors = [
  {
    vendor: "A",
    badge_color: "#FF0000",
  },
  {
    vendor: "B",
    badge_color: "#4682B4",
  },
  {
    vendor: "C",
    badge_color: "#FFA500",
  },
  {
    vendor: "D",
    badge_color: "#228B22",
  },
  {
    vendor: "E",
    badge_color: "#FF4500",
  },
  {
    vendor: "F",
    badge_color: "#663399",
  },
];

export default ({ connectVendorIndex }) => {
  return (
    <div className={styles.dummyConnections}>
      {dummyApparelVendors.map((dummyVendor, index) => (
        <DummyConnectionSlot
          dummyVendor={dummyVendor}
          slotIndex={index}
          connectVendorIndex={connectVendorIndex}
        />
      ))}
    </div>
  );
};
