import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

import DummyConnectionPanel from "./DummyConnectionPanel";
import SettingsIcon from "@mui/icons-material/Settings";

function HowItWorks() {
  const connectionIntervalRef = useRef(null);
  const [connectVendorIndex, setConnectVendorIndex] = useState(null);

  const panelSlotIndexes = [0, 2, 3, 4]; // sorted indices for slots we want to animate

  const getNextSlotIndex = (prevSlotIndex) => {
    if (prevSlotIndex === null) {
      return panelSlotIndexes[0];
    }

    const nextSlotIndex = panelSlotIndexes.find((slotIndex) => {
      return slotIndex > prevSlotIndex;
    });

    return nextSlotIndex;
  };

  useEffect(() => {
    if (connectVendorIndex === panelSlotIndexes[-1]) {
      clearInterval(connectionIntervalRef.current);
    }
  }, [connectVendorIndex]);

  useEffect(() => {
    const howItWorksContainer = document.getElementById("howItWorks");
    const options = {
      root: null, // use the viewport as the root
      threshold: 0.5, // trigger when at least 50% of the component is in view
    };

    const callback = (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          connectionIntervalRef.current = setInterval(() => {
            setConnectVendorIndex((prevSlotIndex) =>
              getNextSlotIndex(prevSlotIndex),
            );
          }, 1250);
        }
      }
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(howItWorksContainer);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    return () => clearInterval(connectionIntervalRef.current);
  }, []);

  return (
    <div className={styles.howItWorksContainer} id="howItWorks">
      <div className={styles.howItWorksWrapper}>
        <div className={styles.howItWorksHeader}>
          <SettingsIcon />
          <div>
            <span>How It Works</span>
          </div>
        </div>
        <div className={styles.stepsWrapper}>
          <div className={styles.howItWorksStep}>
            <div className={styles.stepNumber}>
              <span>1</span>
            </div>
            <div className={styles.stepDesc}>
              <div>
                <span>Add Your Vendors</span>
              </div>
              <div>
                <p>
                  Add your web accounts with all major apparel vendors. Your
                  special and group pricing is fully supported, and prices
                  always match what you would see on your vendors' websites.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.howItWorksStep}>
            <div className={styles.stepNumber}>
              <span>2</span>
            </div>
            <div className={styles.stepDesc}>
              <div>
                <span>Compare Pricing & Availability</span>
              </div>
              <div>
                <p>
                  Search all vendor catalogs at once to find the best apparel items. See <strong>your personalized pricing</strong>, promotions, and real-time inventory numbers at each warehouse from all vendors in one easy-to-use tool.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.howItWorksStep}>
            <div className={styles.stepNumber}>
              <span>3</span>
            </div>
            <div className={styles.stepDesc}>
              <div>
                <span>Add To Cart</span>
              </div>
              <div>
                <p>
                  Manage all of your carts on one page and checkout through your
                  vendor's existing shipping and billing channels, so you can
                  keep the same great relationship you've worked hard to build
                  with them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DummyConnectionPanel connectVendorIndex={connectVendorIndex} />
    </div>
  );
}

export default HowItWorks;
