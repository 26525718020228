import React from "react";
import styles from "./styles.module.scss";

export default ({ apparelSrcImg, apparelItemName, dummyVendors, logoDetails }) => {
  const getLogoHeight = (logoSrc) => {
    const logoDetail = logoDetails.find(detail => detail.src === logoSrc);
    return logoDetail ? logoDetail.height : 'auto';
  };

  return (
    <div className={styles.slideContent}>
      <div>
        <img src={apparelSrcImg} alt={null} className={styles.apparelImage} />
      </div>
      <div className={styles.dummyVendors}>
        <div className={styles.dummyApparelName}>
          <span>{apparelItemName}</span>
        </div>
        {dummyVendors.map((dummyVendor, index) => (
          <div className={`${styles.dummyVendorSlot}`} data-selected={dummyVendor["is_selected"]} key={index}>
            <div className={styles.imageContainer}>
              <img src={dummyVendor["vendor"]} alt="Vendor Logo" style={{ height: getLogoHeight(dummyVendor["vendor"]) }} />
            </div>
            <div className={styles.inventoryContainer}>
              <span>{dummyVendor["inventory_status"]}</span>
            </div>
            <div className={styles.priceContainer}>
              <span>{dummyVendor["price"]}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
