import axios from "axios";
import { Auth } from "aws-amplify";

const BASE_API_URL = "https://33e3pn2sh2.execute-api.us-east-1.amazonaws.com";

const lilProductClient = axios.create({
  baseURL: BASE_API_URL,
});

lilProductClient.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const jwtToken = session.accessToken.jwtToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    },
  };
});

export default lilProductClient;
