import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useProductContext } from "./ProductPage";
import { formatBrandName } from "utilities/formatBrandName";

const ProductHeaderDetails = () => {
  const { styleNumber, brand, shortDescription } = useProductContext();

  const fontFamilyStyle = { fontFamily: "Arial, sans-serif" };
  const descriptionStyle = { ...fontFamilyStyle };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="wrap"
      marginBottom="1.5em"
    >
      {isSmallScreen ? (
        // Small Screen Layout
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          marginRight="2em"
        >
          <Box>
            <Typography
              variant="h4"
              component="span"
              fontWeight="bold"
              sx={fontFamilyStyle}
            >
              {styleNumber}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h4"
              component="span"
              sx={{ ...fontFamilyStyle, whiteSpace: "nowrap" }}
            >
              {formatBrandName(brand)}
            </Typography>
          </Box>
        </Box>
      ) : (
        // Default Layout
        <Box display="flex" alignItems="flex-end" marginRight="2em">
          <Box>
            <Typography
              variant="h4"
              component="span"
              fontWeight="bold"
              sx={fontFamilyStyle}
            >
              {styleNumber} •&nbsp;
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h4"
              component="span"
              sx={{ ...fontFamilyStyle, whiteSpace: "nowrap" }}
            >
              {formatBrandName(brand)}
            </Typography>
          </Box>
        </Box>
      )}
      <Box display="flex" alignItems="flex-end">
        <Typography variant="h5" component="p" className="text-charcoal" sx={descriptionStyle}>
          {shortDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductHeaderDetails;
