import React from "react";
import TeamSection from "./TeamSection";
import StorySection from "./StorySection";
import StoryPageFAQ from "./StoryPageFAQ";
import Footer from "../landing_page/Footer";

const OurStory = () => {

  return (
    <div>
      <TeamSection />
      <StorySection />
      <StoryPageFAQ />
      <Footer />
    </div>
  );
};

export default OurStory;
