import React from "react";

import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import black_text_logo from "../../header/dgi_logo_black.png";

export default () => {
  const navigate = useNavigate();

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerResources}>
        <div className={styles.logoContainer} onClick={() => navigate("/")}>
          <img
            src={black_text_logo}
            alt="Company logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.centeredContent}>
          <div className={styles.footerSection}>
            <div className={styles.sectionTitle}>
              <span>Resources</span>
            </div>
            <div
              className={styles.resourceLink}
              onClick={() => navigate("/our-story")}
            >
              <span>Our story</span>
            </div>
            <div
              className={styles.resourceLink}
              onClick={() => navigate("/privacy")}
            >
              <span>Privacy</span>
            </div>
            <div
              className={styles.resourceLink}
              onClick={() => navigate("/terms")}
            >
              <span>Terms</span>
            </div>
          </div>
          <div className={styles.footerSection}>
            <div className={styles.sectionTitle}>
              <span>Contact</span>
            </div>
            <div>
              <span>ian@dgiapparel.com</span>
            </div>
            <div>
              <span>+1(919)-495-7693</span>
            </div>
            <div>
              <a 
                href="https://calendly.com/ian-dgi/onboarding?month=2024-01" 
                style={{ color: 'rgb(125 125 221)' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Get a demo
              </a>
            </div>
          </div>
        </div>
        <div className={styles.emptySpace}></div>
      </div>
      <div className={styles.rightsTag}>
        <span>© 2024 DGI Apparel, Inc. All Rights Reserved</span>
      </div>
    </div>
  );
};
