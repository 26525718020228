import React, { useEffect, useState } from "react";
import ABLogo from '../../assets/ABLogo.png';
import ASLogo from '../../assets/ASLogo.png';
import SSLogo from '../../assets/SSLogo.png';
import SMLogo from '../../assets/SMLogo.png';
import CMLogo from '../../assets/CMLogo.png';
import PGLogo from '../../assets/PGLogo.png';
import ACCLogo from '../../assets/ACCLogo.png';
import STALogo from '../../assets/STALogo.png';

import styles from "./styles.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";

export default ({ dummyVendor, slotIndex, connectVendorIndex }) => {
  const [connect, setConnection] = useState(false);

  // Array of logos with their custom heights
  const logoDetails = [
    { src: ABLogo, height: '40px' },
    { src: ASLogo, height: '35px' },
    { src: SSLogo, height: '26px' },
    // { src: SMLogo, height: '38px' },
    { src: CMLogo, height: '28px' },
    // { src: PGLogo, height: '42px' },
    { src: STALogo, height: '46px' },
    { src: ACCLogo, height: '46px' },
  ];

  const getAnimationDelay = (seconds) => {
    return seconds.toString() + "s";
  };

  useEffect(() => {
    if (connectVendorIndex === slotIndex) {
      setTimeout(() => {
        setConnection(true);
      }, 400);
    }
  }, [connectVendorIndex, slotIndex]);

  const renderConnectionStatus = connect ? (
    <>
      <CheckIcon />
      <div>
        <span>Connected</span>
      </div>
    </>
  ) : (
    <>
      <AddCircleOutlineIcon />
      <div>
        <span>Add</span>
      </div>
    </>
  );

  return (
    <div className={styles.dummyConnection} data-top-slot={slotIndex === 0}>
      <div className={styles.dummyVendorName}>
        {/* Display the logo and height based on the slotIndex */}
        <img src={logoDetails[slotIndex].src} alt="Vendor Logo" style={{ height: logoDetails[slotIndex].height }} />
      </div>
      <div
        className={styles.dummyConnectionStatus}
        sx={{ animationDelay: getAnimationDelay(slotIndex) }}
        data-btn-press={connectVendorIndex === slotIndex}
        data-connected={connect}
      >
        {renderConnectionStatus}
      </div>
    </div>
  );
};
