import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useProductContext } from "./ProductPage";
import vendors from "constants/vendors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// TODO: get AS link from product page data resp
const disabledVendorNames = new Set(["Augusta Sportswear"]);

const ProductLongDescription = ({ selectedVendorKey, vendorProduct }) => {
  const { longDescriptionPoints } = useProductContext();
  const vendorName = vendors[selectedVendorKey]?.name ?? null;
  const disableVendorLink = disabledVendorNames.has(vendorName);

  const vendorUrl = vendorProduct ? vendorProduct.data?.url : "#";

  return (
    <Box sx={{ maxWidth: "450px", marginTop: "1em", marginLeft: "1em" }}>
      <Typography
        variant="h6"
        component="h2"
        sx={{ marginBottom: "0.5em", marginLeft: "0.5em", marginTop: "0.5em" }}
      >
        Product Details
      </Typography>

      {longDescriptionPoints &&
        longDescriptionPoints.map((line, index) => (
          <React.Fragment key={index}>
            <div
              className="flex flex-row gap-[6px]"
              style={{ marginBottom: "6px" }}
            >
              <span>•</span>
              <span>
                {line}
                <br />
              </span>
            </div>
          </React.Fragment>
        ))}

      {vendorName && !disableVendorLink && (
        <Typography
          variant="body1"
          sx={{ 
            marginTop: "2em", 
            marginLeft: "0.6em",
            width: "fit-content" 
          }}
        >
          <Link
            href={vendorUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              ".vendor-link": {
                color: "rgba(0, 110, 165, 1)", // blueblue
              },
              "&:hover": {
                textDecorationLine: "rgba(0, 88, 141, 1)", // denim
                ".vendor-link": {
                  color: "rgba(0, 88, 141, 1)", // denim
                }
              }
            }}
            className="decoration-blueblue"
          >
            <span className="vendor-link">View on {vendorName}'s Site</span>
            <OpenInNewIcon className="vendor-link" fontSize="small" />
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default ProductLongDescription;
