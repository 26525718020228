import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ShopIcon from '@mui/icons-material/Shop';
import CardMedia from '@mui/material/CardMedia';

const ExplainerVideo = () => {
  return (
    <Box sx={{ padding: 3, textAlign: 'center', backgroundColor: '#f5f5f7', marginBottom: '1.25rem', paddingBottom: '6rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2.5, paddingTop: '3rem' }}>
        <ShopIcon sx={{ fontSize: '2.75rem', display: { xs: 'none', sm: 'block' } }} />
        <Typography variant="h4" component="span">
          Built For Inventory Purchasers
        </Typography>
      </Box>
      <Typography variant="subtitle1" sx={{ marginTop: 4, marginBottom: 3, maxWidth: '660px', marginX: 'auto' }}>
        Whether you’re new to inventory, or an experienced purchaser, you’re going to love DGI. 
        Your days of juggling dozens of websites and phone calls are history!
      </Typography>
      <CardMedia
        component="video"
        sx={{ maxWidth: '900px', height: 'auto', width: '100%', margin: '0 auto' }}
        src="https://d34c0c1in94n75.cloudfront.net/DGIApparelExplainerVideo.mp4"
        controls
      />
    </Box>
  );
};

export default ExplainerVideo;
