import React from "react";
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ProductPageImages from "./ProductPageImages";
import ProductCategories from "./ProductCategories";

const ImageCategoriesGroup = () => {
    const theme = useTheme();
    const isLGOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));
    const isSMOrSmaller = useMediaQuery('(max-width:768px)');
    const isLGOrAbove = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box 
            display="flex"
            flexDirection="column"  // Always use column for vertical alignment
            alignItems="center"    // Center items horizontally (in column mode)
            justifyContent="center" // Center items vertically (in column mode)
            width="auto"
        >
            <ProductPageImages />
            <ProductCategories />
        </Box>
    );
}

export default ImageCategoriesGroup;
