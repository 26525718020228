import React, { useState } from "react";
import { Controller } from "react-hook-form";
import styles from "./styles.module.scss";
import RoleDropdownmenu from "./RoleDropdownMenu";
import StateDropdownMenu from "./StateDropdownMenu";
import { isValidPhoneNumber } from "libphonenumber-js";
import GoogleAutoComplete from 'react-google-autocomplete';

function UserInfoFields({
  control,
  errors,
  setValue,
  role,
  state,
  setRole,
  setState,
}) {
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const validatePhoneNumber = (value) => {
    if (isValidPhoneNumber(value, { defaultCountry: 'US' })) {
      return true;
    } else {
      return 'Phone number is not valid';
    }
  };

  const handleManualAddressInput = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  return (
    <div className={styles.smallFieldsContainer}>
      <label>
        <div className={styles.labelText}>Shop Name*</div>
        <Controller
          name="shopName"
          control={control}
          rules={{ required: 'Shop name is required', minLength: { value: 1, message: 'Shop name must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.shopName && <span className={styles.errorMessage}>{errors.shopName.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Phone Number*</div>
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: 'Phone number is required',
            validate: validatePhoneNumber,
          }}
          render={({ field }) => <input {...field} />}
        />
        {errors.phoneNumber && <span className={styles.errorMessage}>{errors.phoneNumber.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>First Name*</div>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: 'First name is required', minLength: { value: 1, message: 'First name must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.firstName && <span className={styles.errorMessage}>{errors.firstName.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Last Name*</div>
        <Controller
          name="lastName"
          control={control}
          rules={{ required: 'Last name is required', minLength: { value: 1, message: 'Last name must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.lastName && <span className={styles.errorMessage}>{errors.lastName.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Your Role*</div>
        <Controller
          name="role"
          control={control}
          rules={{ required: 'Role is required', minLength: { value: 1, message: 'Role must be at least 1 characters' } }}
          render={({ field: { onChange } }) => <RoleDropdownmenu onChange={onChange} role={role} setRole={setRole} />}
        />
        {errors.role && <span className={styles.errorMessage}>{errors.role.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>How did you hear about us?*</div>
        <Controller
          name="referral"
          control={control}
          rules={{ required: 'Referral is required', minLength: { value: 1, message: 'Referral must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.referral && <span className={styles.errorMessage}>{errors.referral.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Shop Address*</div>
        <Controller
          name="location"
          control={control}
          rules={{ required: 'Location is required', minLength: { value: 1, message: 'Location must be at least 1 characters' } }}
          render={({ field: { onChange, value } }) => (
            <>
              <GoogleAutoComplete
                apiKey={GOOGLE_MAPS_API_KEY}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: ["us", "ca"] },
                }}
                onPlaceSelected={(place) => {
                  const streetNumber = place.address_components.find((component) =>
                    component.types.includes('street_number')
                  )?.long_name || '';
                  const streetName = place.address_components.find((component) =>
                    component.types.includes('route')
                  )?.long_name || '';
                  const street = `${streetNumber} ${streetName}`.trim();
                  onChange(place.formatted_address);

                  // Extract city, state, and zip code from the place object
                  const addressComponents = place.address_components;
                  const city = addressComponents.find((component) => component.types.includes('locality'))?.long_name || addressComponents.find((component) => component.types.includes('sublocality_level_1'))?.long_name;
                  const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.short_name || '';
                  const zipCode = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';
                  // Set the values of city, state, and zip code fields
                  setValue('street', street);
                  setValue('city', city);
                  setValue('state', state);
                  setState(state);
                  setValue('zip', zipCode);
                }}
                onChange={(e) => {
                  onChange(e);
                  handleManualAddressInput(e);
                }}
                value={value}
              />
              <input
                type="text"
                name="location"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  handleManualAddressInput(e);
                }}
                style={{ display: 'none' }}
              />
            </>
          )}
        />
        {errors.street && <span className={styles.errorMessage}>{errors.street.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Street*</div>
        <Controller
          name="street"
          control={control}
          render={({ field }) => <input {...field} />}
        />
      </label>
      <label>
        <div className={styles.labelText}>Apt/Suite/Unit (optional)</div>
        <Controller
          name="address2"
          control={control}
          render={({ field }) => <input {...field} />}
        />
      </label>
      <label>
        <div className={styles.labelText}>City*</div>
        <Controller
          name="city"
          control={control}
          rules={{ required: 'City is required', minLength: { value: 1, message: 'City must be at least 1 characters' } }}
          render={({ field }) => <input {...field} value={field.value} />}
        />
        {errors.city && <span className={styles.errorMessage}>{errors.city.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>State*</div>
        <Controller
          name="state"
          control={control}
          rules={{ required: 'State is required' }}
          render={({ field: { onChange, value } }) => <StateDropdownMenu state={state} setState={setState} onChange={onChange} value={value} />}
        />
        {errors.state && <span className={styles.errorMessage}>{errors.state.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Zip Code*</div>
        <Controller
          name="zip"
          control={control}
          rules={{ required: 'Zip code is required', minLength: { value: 5, message: 'Zip code must be at least 5 digits' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.zipCode && <span className={styles.errorMessage}>{errors.zipCode.message}</span>}
      </label>
    </div>
  );
}

export default UserInfoFields;
