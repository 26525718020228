import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ShirtShowLogo from '../../assets/ShirtShowLogo.png';
import PrintavoLogo from '../../assets/PrintavoLogo.png';
import PrintHustlersLogo from '../../assets/PrintHustlersLogo.png';

const MediaSection = () => {
  const openPrintHustlersLink = () => {
    window.open("https://www.youtube.com/watch?v=Fo_VajMSkcM", "_blank");
  };

  const openPrintavoLink = () => {
    window.open("https://www.printavo.com/blog/how-to-use-software-to-manage-your-inventory-strategy/", "_blank");
  };

  const openShirtShowLink = () => {
    window.open("https://www.youtube.com/watch?v=LcPZvdTUBpM", "_blank");
  };

  return (
    <Box
      bgcolor="#fff"
      color="primary.contrastText"
      sx={{
        marginBottom: '20px',
        paddingTop: '50px',
        paddingBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& > :last-child': {
          marginRight: '0',
          '@media (max-width: 1040px)': {
            marginRight: '40px',
          },
        },
      }}
    >
      <Typography variant="body1" sx={{ color: '#aeafb1', marginBottom: '20px', fontSize: '24px' }}>As Featured In</Typography>
      <Box
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '20px', width: '100%',
          maxWidth: '1000px',
          paddingLeft: '40px',
          '@media (max-width: 900px)': {
            flexDirection: 'column',
            '& > *': {
              marginBottom: '48px',
            },
            '& > :last-child': {
              marginBottom: '0',
            },
          },
          }}
      >
        <a href="https://www.youtube.com/watch?v=LcPZvdTUBpM" target="_blank" rel="noopener noreferrer" onClick={openShirtShowLink}>
          <img src={ShirtShowLogo} alt="ShirtShowLogo" style={{ width: '166px', height: 'auto', cursor: 'pointer', opacity: 0.4, transition: 'opacity 0.3s ease' }}
            onMouseEnter={(e) => e.currentTarget.style.opacity = "0.9"}
            onMouseLeave={(e) => e.currentTarget.style.opacity = "0.4"} />
        </a>
        <a href="https://www.printavo.com/blog/how-to-use-software-to-manage-your-inventory-strategy/" target="_blank" rel="noopener noreferrer" onClick={openPrintavoLink}>
          <img src={PrintavoLogo} alt="PrintavoLogo" style={{ width: '330px', height: 'auto', marginLeft: window.innerWidth <= 900 ? '0' : '30px', cursor: 'pointer', opacity: 0.4, transition: 'opacity 0.3s ease' }}
            onMouseEnter={(e) => e.currentTarget.style.opacity = "0.9"}
            onMouseLeave={(e) => e.currentTarget.style.opacity = "0.4"} />
        </a>
        <a href="https://www.youtube.com/watch?v=Fo_VajMSkcM" target="_blank" rel="noopener noreferrer" onClick={openPrintHustlersLink}>
          <img src={PrintHustlersLogo} alt="PrintHustlersLogo" style={{ width: '260px', height: 'auto', cursor: 'pointer', opacity: 0.4, transition: 'opacity 0.3s ease' }}
            onMouseEnter={(e) => e.currentTarget.style.opacity = "0.9"}
            onMouseLeave={(e) => e.currentTarget.style.opacity = "0.4"} />
        </a>
      </Box>
    </Box>
  );
};

export default MediaSection;
