import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Button, Stack, Box } from "@mui/material";
import SearchBar from "./SearchBar";
import ProfileMenu from "./ProfileMenu";
import black_text_logo from "./dgi_logo_black.png";
import white_text_logo from "./dgi_logo_white.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useUserStateContext } from "../App";
import { useNavigate } from "react-router-dom";
import { AppBar } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SubHeader from "./SubHeader";
import { Backdrop } from "@mui/material";

function SignInButton() {
  const navigate = useNavigate();
  return (
    <Button
      variant="text"
      sx={{
        color: "black",
        borderColor: "black",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "12px",
        marginRight: "20px",
        fontSize: "1.1em",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          opacity: 0.8,
        },
        typography: "inherit",
      }}
      onClick={() => navigate("/auth")}
    >
      Sign In / Sign Up
    </Button>
  );
}

const Header = React.memo(function Header({
  windowWidth,
  setHeaderHeight = null,
  landingPage = false,
}) {
  const navigate = useNavigate();
  const { isUserAuthed, userCreds } = useUserStateContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);

  const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState(null);
  const mainMenuOpen = Boolean(mainMenuAnchorEl);

  useEffect(() => {
    if (setHeaderHeight) {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.clientHeight);
      }
    }
  }, [windowWidth]);

  const handleMainMenuClick = (event) => {
    if (menuOpen) {
      handleMainMenuClose();
    } else {
      setMainMenuAnchorEl(event.currentTarget);
      setMenuOpen(true);
    }
  };

  const handleMainMenuClose = () => {
    setMainMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const displayAuthedFeatures = isUserAuthed && userCreds.shopName;
  const logo = landingPage ? black_text_logo : white_text_logo;

  const renderMenuIcon = !menuOpen ? (
    <MenuIcon className={styles.iconBlack} />
  ) : (
    <CloseIcon className={styles.iconBlack} />
  );

  const showBackdrop = isSearchFocused && autoCompleteSuggestions.length > 0;

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        className={`${!landingPage && "mb-[1rem]"} bg-white`}
      >
        {isUserAuthed ? (
          <>
            <Box
              className={`min-h-header relative
              flex items-center justify-center
              ${landingPage ? "bg-altHeader" : "bg-denim"}`}
              ref={headerRef}
            >
              <Box className={`
                max-w-[1600px] w-full 
                flex flex-row items-center justify-between gap-[12px]`}
                sx={{
                  zIndex: showBackdrop && 1301, // needs to supercede the backdrop
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="flex w-full max-w-575 gap-3"
                >
                  <Box
                    className="flex cursor-pointer pl-5px"
                    sx={{
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={() => navigate("/")}
                  >
                    <img src={logo} alt="Company logo" className="h-2.5R" />
                  </Box>
                  {displayAuthedFeatures && (
                    <SearchBar
                      isFocused={isSearchFocused}
                      setIsFocused={setIsSearchFocused}
                      autoCompleteSuggestions={autoCompleteSuggestions}
                      setAutoCompleteSuggestions={setAutoCompleteSuggestions}
                    />
                  )}
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Stack
                    direction="row"
                    className="hidden lg:flex gap-2.5R pr-[2.75rem]"
                  >
                    <div
                      id="shop-page-header-link"
                      className={`flex items-center gap-[3px]
                      whitespace-nowrap cursor-pointer
                      hover:opacity-80`}
                      onClick={() => navigate("/shop")}

                    >
                      <ShoppingBagOutlinedIcon
                        className="text-grayscaleWhite"
                        fontSize="medium"
                      />
                      <div className="mt-[4px] text-grayscaleWhite">Shop</div>
                    </div>
                    <div
                      id="cart-page-header-link"
                      className={`flex items-center gap-[3px]
                      whitespace-nowrap cursor-pointer
                      hover:opacity-80`}
                      onClick={() => navigate("/cart")}
                    >
                      <ShoppingCartOutlinedIcon
                        className="text-grayscaleWhite"
                        fontSize="medium"
                      />
                      <div className="mt-[4px] text-grayscaleWhite">Cart</div>
                    </div>
                    <div
                      id="cart-page-header-link"
                      className={`flex items-center gap-[6px]
                      whitespace-nowrap cursor-pointer
                      hover:opacity-80`}
                      onClick={() => navigate("/order-history")}
                    >
                      <ImportContactsIcon
                        className="text-grayscaleWhite mt-[2px]"
                        fontSize="small"
                      />
                      <div className="mt-[4px] text-grayscaleWhite">
                        History
                      </div>
                    </div>
                  </Stack>
                  <ProfileMenu />
                </Stack>
                {landingPage && (
                  <div className={styles.landingPageLinks}>
                    <div
                      className={styles.textLink}
                      onClick={() => navigate("/")}
                    >
                      <span>Home</span>
                    </div>
                    <div
                      className={styles.textLink}
                      onClick={() => navigate("/our-story")}
                    >
                      <span>Our Story</span>
                    </div>
                    <SignInButton />
                  </div>
                )}
              </Box>
            </Box>
            <div className="w-full bg-lightblue hidden lg:block">
              <SubHeader windowWidth={windowWidth} />
            </div>
          </>
        ) : (
          <>
            <Box
              className={`min-h-header relative
              flex items-center justify-center ${
                landingPage ? "bg-altHeader" : "bg-denim"
              }`}
              ref={headerRef}
            >
              <Box className="max-w-[1600px] w-full flex flex-row items-center justify-between gap-[12px]">
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="flex w-full max-w-575 gap-3"
                >
                  <Box
                    className="flex cursor-pointer pl-5px"
                    sx={{
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={() => navigate("/")}
                  >
                    <img src={logo} alt="Company logo" className="h-2.5R" />
                  </Box>
                  {displayAuthedFeatures && (
                    <SearchBar
                      isFocused={isSearchFocused}
                      setIsFocused={setIsSearchFocused}
                      autoCompleteSuggestions={autoCompleteSuggestions}
                      setAutoCompleteSuggestions={setAutoCompleteSuggestions}
                    />
                  )}
                </Stack>
                {windowWidth <= 800 ? (
                  <>
                    <div
                      className={styles.hamburgerMenuWrapper}
                      onClick={handleMainMenuClick}
                    >
                      {renderMenuIcon}
                    </div>
                    <Menu
                      anchorEl={mainMenuAnchorEl}
                      open={mainMenuOpen}
                      onClose={handleMainMenuClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/");
                          handleMainMenuClose();
                        }}
                      >
                        Home
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate("/our-story");
                          handleMainMenuClose();
                        }}
                      >
                        Our Story
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate("/auth");
                          handleMainMenuClose();
                        }}
                      >
                        Sign In / Sign Up
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    {landingPage && (
                      <div className={styles.landingPageLinks}>
                        <div
                          className={styles.textLink}
                          onClick={() => navigate("/")}
                        >
                          <span>Home</span>
                        </div>
                        <div
                          className={styles.textLink}
                          onClick={() => navigate("/our-story")}
                        >
                          <span>Our Story</span>
                        </div>
                        <SignInButton />
                      </div>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </>
        )}
      </AppBar>
      <Backdrop
        sx={{
          backgroundColor: "rgba(88, 88, 88, 0.2)",
          zIndex: (theme) => theme.zIndex.modal,
        }}
        open={showBackdrop}
      />
    </>
  );
});

export default Header;
