import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InventoryDetails from "items/inventory_info/InventoryDetails";
import { useProductContext } from "./ProductPage";
import CartErrorAlert from "items/CartErrorAlert";
import { useParams } from "react-router-dom";
import { LOADING_STATES } from "features/productsSlice";
import { calculateSavingsPotential } from "utilities/calculateSavingsPotential";
import { checkIfSaleExists } from "utilities/parseInventoryData";
import { formatPrice } from "utilities/formatPrice";

const InventoryContent = ({
  vendorDataKeys,
  areDataKeysOrdered,
  colorObj,
  loadingTableData,
  setLoadingTableData,
  selectedVendorKey,
  setSelectedVendorKey,
  vendorProducts
}) => {
  const { productColors, vendorFields } = useProductContext();
  const [showCartAlert, setShowCartAlert] = useState(false);
  const [cartAlertContent, setCartAlertContent] = useState();
  const { dgiStyle } = useParams();
  const selectedVendorCode = selectedVendorKey.split("_")[0];
  const productKey = dgiStyle + selectedVendorCode + colorObj?.name;
  const vendorProduct = vendorProducts[productKey];

  const renderCartAlert = showCartAlert && (
    <CartErrorAlert alertContent={cartAlertContent} />
  );

  // in the case that the user didn't already select a tab,
  // we continually assign the cheapest vendor tab until all prices come in
  const firstDataKey = vendorDataKeys.length > 0 ? vendorDataKeys[0] : null;
  const firstDataCode = firstDataKey?.split("_")[0];
  const firstKey = dgiStyle + firstDataCode + colorObj?.name;
  const isVendorAvailable =
  vendorProducts &&
  vendorProducts[firstKey] &&
    (vendorProducts[firstKey]?.loading ===
      LOADING_STATES.SUCCESS_STATUS ||
      vendorProducts[firstKey]?.loading ===
      LOADING_STATES.FAILURE_STATUS);
  const cheapestAvailableVendor = isVendorAvailable
    ? { firstKey: firstDataKey, areKeysOrdered: areDataKeysOrdered }
    : null;

  const calculateSavingsFcn = (quantity, selectedVendorPrice) => {
    calculateSavingsPotential(quantity, selectedVendorPrice, vendorProducts)
  }

  if (!vendorFields || !colorObj) {
    return null;
  }

  const vendorsBestPrices = {};
  for (const vendorDataKey of vendorDataKeys) {
    const vendorCode = vendorDataKey.split("_")[0];
    const vendorProductKey = dgiStyle + vendorCode + colorObj?.name;
    const vendorProduct = vendorProducts[vendorProductKey];
    const vendorSizedPricingData = vendorProduct?.["data"]?.["sized_pricing_data"];
    const saleExists = checkIfSaleExists(vendorSizedPricingData);
    const formattedDollarAmount = formatPrice(vendorProduct?.["data"]?.["product_price"]);
    vendorsBestPrices[vendorDataKey] = {productPrice: formattedDollarAmount, saleExists};
  }

  return (
    <Box
      marginTop="1em"
      paddingTop="0.5em"
      className="hidden md:block" // same as cutoff as on shopping page
    >
      <div>
        {renderCartAlert}
        <InventoryDetails
          hit={vendorFields}
          openInvDetails={true}
          vendorDataKeys={vendorDataKeys}
          productColors={productColors}
          selectedVendorKey={selectedVendorKey}
          setSelectedVendorKey={setSelectedVendorKey}
          showCartAlert={showCartAlert}
          setShowCartAlert={setShowCartAlert}
          setCartAlertContent={setCartAlertContent}
          selectedColor={colorObj}
          fullDisplay={true}
          applyPadding={false}
          applyBorder={true}
          loadingTableData={loadingTableData}
          setLoadingTableData={setLoadingTableData}
          cheapestAvailableVendor={cheapestAvailableVendor}
          vendorProduct={vendorProduct}
          calculateSavingsFcn={calculateSavingsFcn}
          vendorsBestPrices={vendorsBestPrices}
        />
      </div>
    </Box>
  );
};

export default InventoryContent;
