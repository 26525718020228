import React, { useState } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductHeaderDetails from "./ProductHeaderDetails";
import ProductLongDescription from "./ProductLongDescription";
import ColorInventoryGroup from "./ColorInventoryGroup";
import ImageCategoriesGroup from "./ImageCategoriesGroup";
import { useProductContext } from "./ProductPage";
import { CircularProgress, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { createSelectVendorProducts } from "features/productsSlice";
import { useParams } from "react-router-dom";
import DataToggles from "dashboard/DataToggles";

function RenderColorInventoryGroup({
  selectedVendorKey,
  setSelectedVendorKey,
  displayVendors,
  displaySuggestedVendors,
  vendorProducts,
  dgiStyle,
  colorQuery,
  sendJsonMessage
}) {
  if (displayVendors.length === 0 || vendorProducts.length === 0) {
    return null;
  }

  return (
    <ColorInventoryGroup
      selectedVendorKey={selectedVendorKey}
      setSelectedVendorKey={setSelectedVendorKey}
      displayVendors={displayVendors}
      displaySuggestedVendors={displaySuggestedVendors}
      vendorProducts={vendorProducts}
      dgiStyle={dgiStyle}
      colorQuery={colorQuery}
      sendJsonMessage={sendJsonMessage}
    />
  );
}

const ProductPageContent = ({ 
  sendJsonMessage, 
  unsortedDisplayVendors, 
  displaySuggestedVendors, 
  vendorCodes 
}) => {
  const isLGOrAbove = useMediaQuery("(min-width:1250px)");
  const { 
    loadingProductData, 
    vendorFields, 
    colorObj,
  } = useProductContext();
  const [selectedVendorKey, setSelectedVendorKey] = useState("");
  const { dgiStyle, colorQuery } = useParams();
  const selectVendorProducts = createSelectVendorProducts(
    dgiStyle,
    vendorCodes,
    colorObj?.name
  );
  const vendorProducts = useSelector(selectVendorProducts);
  const selectedProductKey = dgiStyle + selectedVendorKey.split("_")[0] + colorObj?.name;
  const vendorProduct = vendorProducts[selectedProductKey];

  function getVendorLowestPrice(vendorKey) {
    const vendorCode = vendorKey.split("_")[0];
    const productKey = dgiStyle + vendorCode + colorObj.name;
    const vendorPrice =
      vendorProducts && vendorProducts[productKey]
        ? vendorProducts[productKey].data?.product_price
        : null;
    if (!vendorPrice) {
      return Infinity;
    }
    return vendorPrice;
  }

  function sortVendorsByPrice(vendorA, vendorB) {
    const vendorAKey = vendorA[0];
    const vendorBKey = vendorB[0];
    const vendorALowestPrice = getVendorLowestPrice(vendorAKey);
    const vendorBLowestPrice = getVendorLowestPrice(vendorBKey);
    if (vendorALowestPrice < vendorBLowestPrice) {
      return -1;
    }
    if (vendorALowestPrice > vendorBLowestPrice) {
      return 1;
    }
    return 0;
  }

  // recreating a field we use in Product that is then passed to InventoryDetails
  let displayVendors = [];
  if (vendorFields && colorObj) {
    displayVendors = unsortedDisplayVendors.sort(sortVendorsByPrice);
  }

  if (loadingProductData) {
    return (
      <div className="flex flex-col gap-[20px] justify-center items-center w-full mt-[85px]">
        <CircularProgress size="80px" />
        <div className="text-[#313131] text-lg">
          <span>Fetching product information...</span>
        </div>
      </div>
    );
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Stack alignItems="center" justifyContent="space-between" className="flex-col md:flex-row">
        <Box className="w-fit">
          <ProductHeaderDetails />
        </Box>
        <Box className="hidden lg:flex">
          <DataToggles onProductPage={true} />
        </Box>
      </Stack>

      {isLGOrAbove ? (
        <Box display="flex" flexDirection="row" width="auto" gap="2rem">
          <Box flex={1} display="flex" flexDirection="column" gap="1rem">
            <ImageCategoriesGroup />
            <ProductLongDescription
              selectedVendorKey={selectedVendorKey}
              vendorProduct={vendorProduct}
            />
          </Box>
          <RenderColorInventoryGroup
            selectedVendorKey={selectedVendorKey}
            setSelectedVendorKey={setSelectedVendorKey}
            displayVendors={displayVendors}
            displaySuggestedVendors={displaySuggestedVendors}
            vendorProducts={vendorProducts}
            dgiStyle={dgiStyle}
            colorQuery={colorQuery}
            sendJsonMessage={sendJsonMessage}
          />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap="2rem">
          <ImageCategoriesGroup />
          <RenderColorInventoryGroup
            selectedVendorKey={selectedVendorKey}
            setSelectedVendorKey={setSelectedVendorKey}
            displayVendors={displayVendors}
            displaySuggestedVendors={displaySuggestedVendors}
            vendorProducts={vendorProducts}
            dgiStyle={dgiStyle}
            colorQuery={colorQuery}
            sendJsonMessage={sendJsonMessage}
          />
          <ProductLongDescription
            selectedVendorKey={selectedVendorKey}
            vendorProduct={vendorProduct}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProductPageContent;
