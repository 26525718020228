import axios from "axios";
import { Auth } from "aws-amplify";

const SS_API_BASE_URL = "https://1a710t26i3.execute-api.us-east-1.amazonaws.com"
const ssClient = axios.create({
  baseURL: SS_API_BASE_URL,
});

ssClient.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const jwtToken = session.accessToken.jwtToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    },
  };
});

export default ssClient;
