import React from "react";
import styles from "./styles.module.scss";
import JayTeamPic from "../../assets/JayTeamPic.png";
import IanTeamPic from "../../assets/IanTeamPic.png";
import ShkreliTeamPic from "../../assets/ShkreliTeamPic.jpg";
import GrantTeamPic from "../../assets/GrantTeamPic.png";

const TeamSection = () => {
  return (
    <div className={styles.teamSectionContainer}>
      <h2 className="text-2xl">Meet Our Team</h2>
      <p className="my-0 max-w-[800px] text-lg text-center">
        Meet the team that powers DGI Apparel's product and customer support
      </p>
      <div className={styles.teamImagesContainer}>
        <div className={styles.teamMember}>
          <img
            src={IanTeamPic}
            alt="Ian's Picture"
            className={styles.teamImage}
          />
          <span className="mt-[10px] text-xl text-charcoal">Ian</span>
        </div>
        <div className={styles.teamMember}>
          <img
            src={GrantTeamPic}
            alt="Grant's Picture"
            className={styles.teamImage}
          />
          <span className="mt-[10px] text-xl text-charcoal">Grant</span>
        </div>
        <div className={styles.teamMember}>
          <img
            src={ShkreliTeamPic}
            alt="Daniel's Picture"
            className={styles.teamImage}
          />
          <span className="mt-[10px] text-xl text-charcoal">Daniel</span>
        </div>
        <div className={styles.teamMember}>
          <img
            src={JayTeamPic}
            alt="Jay's Picture"
            className={styles.teamImage}
          />
          <span className="mt-[10px] text-xl text-charcoal">Jay</span>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
