import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Alphabroder from "../../assets/ABLogo.png";
import CarolinaMade from "../../assets/CMLogo.png";
import SSActivewear from "../../assets/SSLogo.png";
import Sanmar from "../../assets/SMLogo.png";

const vendorLogos = {
  Alphabroder: Alphabroder,
  "Carolina Made": CarolinaMade,
  "S&S Activewear": SSActivewear,
  Sanmar: Sanmar,
};

export const CheckoutHeaderSteps = ({ activeStep }) => {
  if (activeStep !== "options" && activeStep !== "confirmation") {
    return null;
  }

  const activeStepStyling = {
    margin: "0 4px",
  };
  const inactiveStepStyling = {
    color: "rgba(103, 111, 120, 1)",
    margin: "0 4px",
  };
  const optionsStepStyling =
    activeStep === "options" ? activeStepStyling : inactiveStepStyling;
  const confirmationStepStyling =
    activeStep === "confirmation" ? activeStepStyling : inactiveStepStyling;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "38px",
        backgroundColor: "white",
      }}
    >
      <Typography className="text-lg" sx={optionsStepStyling}>Shipping & Billing Info</Typography>
      <Typography sx={{ fontSize: "20px", margin: "0 4px" }}>&gt;</Typography>
      <Typography className="text-lg" sx={confirmationStepStyling}>Order Confirmation</Typography>
    </Box>
  );
};

export const WarehouseName = ({ warehouseName }) => {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Typography sx={{ fontWeight: "600" }}>{warehouseName}</Typography>
    </Box>
  );
};

export const ConfirmationSectionHeader = ({ header }) => {
  return (
    <Typography variant="h6" component="h2" sx={{ fontWeight: "bold", mb: 2 }}>
      {header}
    </Typography>
  );
};

export const CheckoutDisclaimer = ({ vendorName }) => {
  return (
    <Box
      sx={{
        px: "42px",
        py: "12px",
        borderRadius: "2px",
        backgroundColor: "white",
        width: "fit-content",
        mx: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 3,
        backgroundColor: "white",
      }}
      className="checkoutSection confirmationSection receiptSection"
    >
      <Typography align="center" className="text-sm">
        When you place an order on DGI Apparel, your order is transferred
        directly to {vendorName}. All billing and invoicing will still come
        directly from {vendorName}. Questions? Press the chat button 
        at the bottom right of your screen for further assistance.
      </Typography>
    </Box>
  );
};

export const CheckoutHeaderVendorName = ({ vendorName }) => {
  const vendorLogo = vendorLogos[vendorName];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "64px",
        backgroundColor: "white",
        borderBottom: "1px solid lightgray",
      }}
    >
      {vendorLogo && (
        <img src={vendorLogo} alt={vendorName} style={{ maxHeight: "44px" }} />
      )}
    </Box>
  );
};
