import { useStats } from "react-instantsearch";

import { Typography } from "@mui/material";

export default function CustomStats() {
  const { hitsPerPage, nbHits, page, query } = useStats();

  const firstHitNum = page * 10 + 1;
  const lastHitNum = nbHits >= 10 ? (firstHitNum + (hitsPerPage - 1)) : nbHits;
  return (
    <div
      className="flex items-center justify-center
      lg:justify-start
      mt-15 
      w-full
      pl-10px"
    >
      <Typography className="text-charcoal text-sm">
        Showing results {firstHitNum}-{lastHitNum} of {nbHits} for <q>{query}</q>
      </Typography>
    </div>
  );
};
