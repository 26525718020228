import React from 'react';
import styles from './styles.module.scss';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function BuyingGroupInfo({ buyingGroupOption, setBuyingGroupOption, buyingGroupName, setBuyingGroupName }) {
  const options = ["Yes", "No"];

  const toggleCheckbox = (option) => {
    if (option === "No") {
      setBuyingGroupName("");
    }
    setBuyingGroupOption(new Set([option]));
  };

  return (
    <div>
      <div className={styles.distributorTitle}>
        Are you part of a buying group or franchise?
      </div>
      <div className="flex flex-row justify-center space-x-4">
        {options.map((option, index) => (
          <div key={index} className={styles.option}>
            {buyingGroupOption.has(option) ? (
              <CheckBoxIcon style={{ fontSize: 28 }} onClick={() => toggleCheckbox(option)} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} onClick={() => toggleCheckbox(option)} />
            )}
            <label onClick={() => toggleCheckbox(option)}>{option}</label>
          </div>
        ))}
      </div>
      {buyingGroupOption.has("Yes") && (
        <div className={`${styles.column} ${styles.buyingGroupInputWrapper}`}>
          <label className="w-full max-w-[340px]">
            <div className={styles.labelText}>If yes, which?</div>
            <input
              className={styles.buyingGroupNameInput}
              type="text"
              placeholder="Buying Group Name"
              value={buyingGroupName}
              onChange={(e) => setBuyingGroupName(e.target.value)}
            />
          </label>
        </div>
      )}
    </div>
  );
}

export default BuyingGroupInfo;
