import React, { useRef, useState, useEffect } from "react";

import styles from "./styles.module.scss";
import WelcomeSection from "./WelcomeSection";
import MediaSection from "./MediaSection"; // Importing MediaSection
import HowItWorks from "./HowItWorks";
import LandingPageFAQ from "./LandingPageFAQ";
import Slide from "@mui/material/Slide";
import ApparelItem from "./ApparelItem";
import Paper from "@mui/material/Paper";
import Footer from "./Footer";
import Testimonials from "./Testimonials";
import ExplainerVideo from "./ExplainerVideo";

import BlueCrewImg from "../../assets/black_crew.jpg";
import GrayPulloverImg from "../../assets/gray_pullover.png";
import GreenShirtImg from "../../assets/green_shirt.png";
import OrangeShirtImg from "../../assets/orange_shirt.png";

import ABLogo from '../../assets/ABLogo.png';
import ASLogo from '../../assets/ASLogo.png';
import SSLogo from '../../assets/SSLogo.png';
import SMLogo from '../../assets/SMLogo.png';
import CMLogo from '../../assets/CMLogo.png';
import PGLogo from '../../assets/PGLogo.png';
import ACCLogo from '../../assets/ACCLogo.png';
import STALogo from '../../assets/STALogo.png';

const dummyApparelImages = {
  blue_crew: BlueCrewImg,
  orange_shirt: OrangeShirtImg,
  gray_pullover: GrayPulloverImg,
  green_shirt: GreenShirtImg,
};

const dummyApparelNames = {
  blue_crew: "Adult Crewneck Sweater",
  orange_shirt: "50 Cotton/50 Poly T-Shirt",
  gray_pullover: "Hooded Pullover Sweatshirt",
  green_shirt: "100% Cotton T Shirt",
};

const logoDetails = [
  { src: ABLogo, height: '27px' },
  { src: ASLogo, height: '25px' },
  { src: SSLogo, height: '19px' },
  { src: SMLogo, height: '24px' },
  { src: CMLogo, height: '19px' },
  { src: PGLogo, height: '27px' },
  { src: ACCLogo, height: '32px' },
  { src: STALogo, height: '28px' },
];

const vendorLogos = {
  "A": ABLogo,
  "B": ASLogo,
  "C": SSLogo,
  "D": SMLogo,
  "E": CMLogo,
  "F": ACCLogo,
  "G": PGLogo,
  "H": ACCLogo,
  "I": STALogo,
};

const dummyApparelVendors = {
  blue_crew: [
    {
      vendor: "A",
      inventory_status: "In Stock",
      price: "$17.96",
      is_selected: true,
    },
    {
      vendor: "B",
      inventory_status: "In Stock",
      price: "$20.59",
    },
    {
      vendor: "C",
      inventory_status: "No Stock",
      price: "$19.89",
    },
    {
      vendor: "I",
      inventory_status: "In Stock",
      price: "$21.30",
    },
  ],
  orange_shirt: [
    {
      vendor: "E",
      inventory_status: "In Stock",
      price: "$4.76",
    },
    {
      vendor: "G",
      inventory_status: "In Stock",
      price: "$5.02",
    },
    {
      vendor: "A",
      inventory_status: "In Stock",
      price: "$2.80",
      is_selected: true,
    },
    {
      vendor: "H",
      inventory_status: "No Stock",
      price: "$3.96",
    },
  ],
  green_shirt: [
    {
      vendor: "A",
      inventory_status: "In Stock",
      price: "$5.65",
    },
    {
      vendor: "C",
      inventory_status: "In Stock",
      price: "$6.25",
    },
    {
      vendor: "F",
      inventory_status: "In Stock",
      price: "$6.12",
    },
    {
      vendor: "I",
      inventory_status: "In Stock",
      price: "$4.75",
      is_selected: true,
    },
  ],
  gray_pullover: [
    {
      vendor: "A",
      inventory_status: "No Stock",
      price: "$34.32",
    },
    {
      vendor: "B",
      inventory_status: "In Stock",
      price: "$30.53",
      is_selected: true,
    },
    {
      vendor: "C",
      inventory_status: "In Stock",
      price: "$33.02",
    },
    {
      vendor: "H",
      inventory_status: "No Stock",
      price: "$32.80",
    },
  ],
};

export default ({ }) => {
  const howItWorksRef = useRef(null);
  const openingRef = useRef(null);
  const [slideAnimation, setSlideAnimation] = useState(true);
  const [apparelItem, setApparelItem] = useState("green_shirt");
  const apparelIntervalRef = useRef(null);
  const slideStateRef = useRef(1);

  const dummyVendors = dummyApparelVendors[apparelItem] ?? [];

  const scrollToHowItWorksSection = () => {
    howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const dummyApparelItems = [
    "green_shirt",
    "blue_crew",
    "orange_shirt",
    "gray_pullover",
  ];

  const getNextItem = (currentItem) => {
    const numDummyItems = dummyApparelItems.length;
    const itemIndex = dummyApparelItems.findIndex(
      (item) => item === currentItem,
    );

    if (itemIndex === numDummyItems - 1 || currentItem === "") {
      return dummyApparelItems[0];
    }

    return dummyApparelItems[itemIndex + 1];
  };

  Object.keys(dummyApparelVendors).forEach(apparelType => {
    dummyApparelVendors[apparelType] = dummyApparelVendors[apparelType].map(vendor => ({
      ...vendor,
      vendor: vendorLogos[vendor.vendor] || vendor.vendor,
    }));
  });

  useEffect(() => {
    apparelIntervalRef.current = setInterval(() => {
      setSlideAnimation((prevSlideAnimation) => !prevSlideAnimation);

      if (slideStateRef.current === 0) {
        setApparelItem((prevApparelItem) => getNextItem(prevApparelItem));
      }

      slideStateRef.current = slideStateRef.current === 0 ? 1 : 0;
    }, 4000);

    return () => clearInterval(apparelIntervalRef.current);
  }, []);

  return (
    <>
      <div className={styles.landingPageWrapper}>
        <div className={styles.openingContainer} ref={openingRef}>
          <WelcomeSection scrollToHowItWorks={scrollToHowItWorksSection} />
          <Slide
            direction={slideAnimation ? "up" : "down"}
            in={slideAnimation}
            container={openingRef.current}
            timeout={{
              enter: 1000,
              exit: 3000,
            }}
          >
            <Paper className={styles.slideContentBack}>
              <ApparelItem
                apparelSrcImg={dummyApparelImages[apparelItem]}
                apparelItemName={dummyApparelNames[apparelItem]}
                dummyVendors={dummyVendors}
                logoDetails={logoDetails}
              />
            </Paper>
          </Slide>
        </div>
        <MediaSection />
        <div ref={howItWorksRef}>
          <HowItWorks />
        </div>
        <ExplainerVideo />
        <Testimonials />
        <LandingPageFAQ />
        <Footer />
      </div>
    </>
  );
};