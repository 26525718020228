import React from "react";
import styles from "./styles.module.scss";

function StoryPageFAQ() {
  return (
    <div className={styles.faqWrapper}>
      <div className={styles.faqTitle}>
        <span>Frequently Asked Questions</span>
      </div>
      <div className={styles.faqContainerParent}>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>Why this business model?</span>
          <p>
            <span className={styles.shortAnswer}>
              Our users face an increasingly challenging economic climate.
            </span>
            <span>
              {` At our own shop, we’ve experienced rising inventory costs, competition from online printers, and increasingly frugal customers. 
                  It has never been more challenging to run a profitable custom apparel shop than it is today, and we don’t believe shop owners should be taxed any further than they already are.
                  As a result, we charge suppliers a fee to list or advertise on our website, but we will never charge shops to connect their vendors and purchase on DGI Apparel.`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>What about my shop’s data?</span>
          <p>
            <span className={styles.shortAnswer}>
              Selling data is not our business model.
            </span>
            <span>
              {` DGI Apparel will `}
              <em>never</em>
              {` share any identifiable data about your clinic with any third party, for any reason.
                    What you buy, and who you buy from, is kept strictly private – even to our own team.
                    We believe you have a right to absolute privacy, and ensuring the security of all shops’ data is our top priority.`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            Can a service this good really be completely free?
          </span>
          <p>
            <span className={styles.shortAnswer}>We get that a lot.</span>
            <span>
              {` In the custom apparel industry, you’re used to vendors that charge exorbitant monthly fees, and offer terrible support for outdated software. 
                  We’re different – and we’re proud of it. We don’t think of ourselves as a software company.
                   We’re a group of passionate advocates dedicated to the financial well-being of shop owners and their employees. Read our story or reach out to us if you have any questions.
                   We’re your champion – and we’re here to help.`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            This service is great! How can I help?
          </span>
          <p>
            <span className={styles.shortAnswer}>We love referrals!</span>
            <span>
              {` Since we are committed to keeping DGI Apparel completely free, we have no marketing budget and most of our users find us through word-of-mouth.
                  Referrals are the best way to ensure that our name gets out and more shops can take advantage of the platform we've built just for them.
                  Feedback is another great way to help us. If you have ANY ideas on how we can make our service better for you, don't hesitate to reach out at the contacts listed below.`}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default StoryPageFAQ;
