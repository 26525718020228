import React from "react";
import { Stack } from "@mui/material";
import ProductDataToggle from "./ProductDataToggle";
import { DATA_TOGGLE_TYPES } from "./ProductDataToggle";
import { useUserStateContext } from "App";

function DataToggles({ onProductPage = false }) {
  const { userCreds } = useUserStateContext();
  const stylingSpecs = onProductPage
    ? "mb-[25px] mr-[25px] w-auto"
    : "gap-[16px]";
  return (
    <Stack
      direction={onProductPage ? "row" : "column"}
      alignItems={onProductPage ? "start" : "center"}
      className={stylingSpecs}
    >
      <ProductDataToggle
        dataToggleType={DATA_TOGGLE_TYPES.HIDE_PRICES}
        userId={userCreds?.cognitoID}
      />
      <ProductDataToggle
        dataToggleType={DATA_TOGGLE_TYPES.SHOW_ONLY_SYNCED_VENDORS}
        userId={userCreds?.cognitoID}
      />
    </Stack>
  );
}

export default DataToggles;
