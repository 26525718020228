import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Slide, Paper } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import testimonialsData from "../../constants/testimonials";

const TestimonialText = ({ text, author, position, shopName, location }) => {
  return (
    <Box sx={{ textAlign: "center", maxWidth: "1000px" }}>
      <Typography
        variant="body1"
        sx={{ fontSize: "20px", color: "#1d1d1f", display: "inline" }}
      >
        {text}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: "16px", color: "#646464", pt: 4 }}
      >
        {author}, {position}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: "14px", color: "#646464" }}
      >
        {shopName} | {location}
      </Typography>
    </Box>
  );
};

const CircleIndicator = ({ index, currentTestimonialIndex }) => (
  <Box
    sx={{
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: index === currentTestimonialIndex ? 'gray' : 'lightgray',
      margin: '0 5px',
    }}
  />
);

const Testimonials = () => {
  const [slideAnimation, setSlideAnimation] = useState(true);
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonialsData[0]);
  const [enterFrom, setEnterFrom] = useState("left");
  const [exitTo, setExitTo] = useState("right");
  const slideContainerRef = useRef(null);
  const slideIntervalRef = useRef(null);
  const slideStateRef = useRef(1); 
  const testimonialFcnRef = useRef("next");

  const getPrevTestimonial = (currentItem) => {
    const numTestimonials = testimonialsData.length;
    const itemIndex = testimonialsData.findIndex(
      (item) => item === currentItem,
    );

    if (itemIndex === 0) {
      return testimonialsData[numTestimonials-1];
    }

    return testimonialsData[itemIndex - 1];
  }

  const getNextTestimonial = (currentItem) => {
    const numTestimonials = testimonialsData.length;
    const itemIndex = testimonialsData.findIndex(
      (item) => item === currentItem,
    );

    if (itemIndex === numTestimonials - 1) {
      return testimonialsData[0];
    }
  
    return testimonialsData[itemIndex + 1];
  };

  const currentTestimonialIndex = testimonialsData.findIndex(
    (testimonial) => testimonial === currentTestimonial
  );

  const triggerAnimation = () => {
    setSlideAnimation((prevSlideAnimation) => !prevSlideAnimation);
  
    if (slideStateRef.current === 0) {
      setCurrentTestimonial((testimonial) => {
        if (testimonialFcnRef.current === "next") {
          return getNextTestimonial(testimonial);
        } else if (testimonialFcnRef.current === "prev") {
          return getPrevTestimonial(testimonial);
        }
      });
    }

    slideStateRef.current = slideStateRef.current === 0 ? 1 : 0;
  }

  useEffect(() => {
    // this is how we control the duration for which the content appears and
    // how fast the new content comes in
    const intervalTimer = slideAnimation ? 11000 : 4000;
    if (slideIntervalRef.current) {
      clearInterval(slideIntervalRef.current);
    }
    slideIntervalRef.current = setInterval(() => triggerAnimation(), intervalTimer);
  }, [slideAnimation])

  useEffect(() => {
    // we need to make sure we set the directions back to original values after prev hit
    if (slideAnimation) {
      testimonialFcnRef.current = "next";
      setEnterFrom("left");
      setExitTo("right");
    }
  }, [slideAnimation])

  useEffect(() => {
    return () => clearInterval(slideIntervalRef.current);
  }, []);

  const handlePrev = () => {
    setEnterFrom("right")
    setExitTo("left")
    testimonialFcnRef.current = "prev";
    slideStateRef.current = 0;
    setSlideAnimation(false);
  };
  
  const handleNext = () => {
    slideStateRef.current = 0;
    setSlideAnimation(false);
  }; 

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#f5f5fc",
        padding: 3,
        marginBottom: 2.5,
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 2.5 }}>
        Hundreds of shops use DGI every week!
      </Typography>

      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          minHeight: "250px",
          '@media (max-width: 600px)': {
            minHeight: '320px',
          },
          '@media (max-width: 450px)': {
            minHeight: '350px',
          },
        }}
        ref={slideContainerRef}
      >
        <Slide
        direction={slideAnimation ? exitTo : enterFrom}
        in={slideAnimation}
          anchor="right"
          mountOnEnter
          unmountOnExit
          container={slideContainerRef.current}
          timeout={{
            enter: 3000,
            exit: 3000,
          }}
        >
          <Paper 
            sx={{
              background: "transparent",
              minHeight: "250px",
              boxShadow: "none"
            }}
            className="flex items-center"
          >
            <TestimonialText
              text={currentTestimonial.text}
              author={currentTestimonial.author}
              position={currentTestimonial.position}
              shopName={currentTestimonial.shopName}
              location={currentTestimonial.location}
            />
          </Paper>
        </Slide>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 1,
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          <IconButton onClick={handlePrev} sx={{ marginRight: 1 }}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Box sx={{ display: "flex" }}>
            {testimonialsData.map((_, index) => (
              <CircleIndicator
                key={index}
                index={index}
                currentTestimonialIndex={currentTestimonialIndex}
              />
            ))}
          </Box>
          <IconButton onClick={handleNext} sx={{ marginLeft: 1 }}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;
