import React from "react";
import styles from "./styles.module.scss";

function LandingPageFAQ({}) {
  return (
    <div className={styles.faqWrapper}>
      <div className={styles.faqTitle}>
        <span>Frequently Asked Questions</span>
      </div>
      <div className={styles.faqContainerParent}>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            Is DGI Apparel compatible with my vendors?
          </span>
          <p>
            <span className={styles.shortAnswer}>
              DGI Apparel supports most of the major apparel vendors in the
              United States.
            </span>
            <span>
              {` You'll be able to see which vendor accounts you can add once you sign up. 
                If you have any questions, give us a call at +1(919)-495-7693.`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            Is DGI Apparel a distributor? Is it a buying group?
          </span>
          <p>
            <span className={styles.shortAnswer}>
              DGI Apparel is neither a distributor, nor a buying group.
            </span>
            <span>
              {` We don't buy and resell products, and we don't negotiate deals on behalf of our members. 
                DGI is a purchasing platform that helps shops order smarter and easier from the vendors they already use. 
                You still qualify for all vendor promotions and rebates through your vendors.`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            Do I change who I order from?
          </span>
          <p>
            <span className={styles.shortAnswer}>
              Of course not! You keep your vendors and relationships.
            </span>
            <span>
              {` DGI is a purchasing tool that brings all of your existing vendors into one platform to make purchasing faster and easier. 
                You still order from the vendors you know and trust, with the same pricing, shipping, billing, and support you're used to. 
                Your reps will still receive their commissions and your relationships with them will not be impacted at all. 
                When you use DGI, the only thing that really changes is your shopping experience - which becomes a whole lot better!`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            Will I still get my negotiated, GPO, or corporate pricing?
          </span>
          <p>
            <span className={styles.shortAnswer}>
              DGI Apparel supports all special pricing
            </span>
            <span>
              {`, including buying group deals, corporate group negotiated deals, and special pricing provided by your representatives. 
                Once you add your vendor account to DGI, your special pricing with that vendor will automatically be reflected on DGI. 
                You'll still qualify for rebates, promotions, and loyalty programs with your vendors, too!`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>Why is DGI Apparel free?</span>
          <p>
            {/* <span className={styles.shortAnswer}>
              Corporations pay, so that independently-owned apparel shops don't have to.
            </span>
            <span>
              {` DGI Apparel provides rich content listings, payment processing, and order management capabilities to manufacturers that sell direct on DGI. 
              DGI will never charge shops to use our platform. If you want to learn more about our business model, call us at +1(919)-495-7693.`}
            </span> */}
            <span className={styles.shortAnswer}>
              The goal of DGI Apparel is to empower custom apparel shops - not
              profit from them.
            </span>
            <span>
              {` This website is a passion project that stemmed from knowing first-hand the pain of managing several different vendor accounts. 
              Today, we're excited to have vendors that pay to list on the site so that we can continue to keep DGI free for shop owners.
              We will NEVER charge a shop to connect their vendors and start purchasing on DGI.`}
            </span>
          </p>
        </div>
        <div className={styles.faqChild}>
          <span className={styles.faqQuestion}>
            Do I have to add my vendor accounts to use DGI Apparel?
          </span>
          <p>
            <span className={styles.shortAnswer}>
              Yes, you must add your vendor accounts to use DGI.
            </span>
            <span>
              {` Vendors charge different prices to each shop, even for the exact same items. 
                Pricing you see on each vendor's website is specific to your shop, 
                depending on whether you're part of a GPO or have negotiated a particular pricing tier with your rep. 
                By adding your vendor accounts, DGI can display your shop's own unique prices 
                and local stock information while you shop, and allows you to place orders with your vendors.`}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPageFAQ;
