import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import { getURLColor, getValidImageUrls } from "utilities/imageUrlUtils";

const ImageList = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginRight: "10px",
  justifyContent: "flex-start",
  height: "100%",
});

const ThumbnailImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ isSelected }) => ({
  width: "50px",
  height: "auto",
  marginBottom: "8px",
  cursor: "pointer",
  border: isSelected ? "2px solid lightgray" : "none",
  borderRadius: isSelected ? "8px" : "none",
  "&:hover": {
    opacity: 0.8,
  },
}));

const Arrow = styled("div")({
  cursor: "pointer",
  alignSelf: "center",
  "&:hover": {
    opacity: 0.8,
  },
});

const Placeholder = ({isScreenBelow440px}) => (
  <Box
    sx={{
      flexGrow: 1,
      height: '400px',
      bgcolor: '#F6F6F6',
      width: isScreenBelow440px ? '300px' : '360px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#d2d6da',
      borderRadius: 1,
      p: 1.5,
    }}
  >
    <Typography variant="h1" sx={{ color: 'inherit', marginBottom: 0 }}>
      DGI
    </Typography>
    <Typography variant="body1" sx={{ color: 'inherit' }}>
      Image coming soon
    </Typography>
  </Box>
);

const ProductPageImages = () => {
  const { dgiStyle, colorQuery } = useParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  const theme = useTheme();
  const isScreenAbove550px = useMediaQuery(theme.breakpoints.up(550));
  const isScreenBelow440px = useMediaQuery(theme.breakpoints.down(440));

  useEffect(() => {
    (async () => {
      const searchColor = { name: colorQuery};
      const imgColor = getURLColor(searchColor);
      const newImageUrls = getValidImageUrls(dgiStyle, imgColor);
      setImageUrls(newImageUrls);
    })();
  }, [dgiStyle, colorQuery]);

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );
  };

  const onImgError = (url) => {
    setImageUrls((prevImageUrls) => {
      const urlIndex = prevImageUrls.findIndex((prevUrl) => prevUrl === url);
      const newImageUrls = prevImageUrls
        .slice(0, urlIndex)
        .concat(prevImageUrls.slice(urlIndex + 1));
      return newImageUrls;
    });
  };

  const hasImages = imageUrls.length > 0;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width="auto"
      maxHeight="600px"
    >
      {isScreenAbove550px && hasImages && (
        <ImageList>
          {imageUrls.map((url, index) => (
            <ThumbnailImage
              key={index}
              src={url}
              onError={() => onImgError(url)}
              onClick={() => setSelectedImageIndex(index)}
              isSelected={selectedImageIndex === index}
            />
          ))}
        </ImageList>
      )}
      <Arrow onClick={goToPreviousImage}>
        <ArrowBackIosNewIcon />
      </Arrow>

      {hasImages ? (
        <img
          src={imageUrls[selectedImageIndex]}
          alt="Selected Product"
          style={{
            width: isScreenBelow440px ? "300px" : "360px",
            height: "auto",
          }}
        />
      ) : (
        <Placeholder />
      )}

      <Arrow onClick={goToNextImage}>
        <ArrowForwardIosIcon />
      </Arrow>
    </Box>
  );
};

export default ProductPageImages;