import React, { useState } from "react";
import styles from "./styles.module.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function DecoratingInfo({ decoratingOptions, setDecoratingOptions }) {
  const options = [
    "Screen Printer",
    "Contract Screen Printer",
    "Embroiderer",
    "Contract Embroiderer",
    "Direct-To-Garment Printer",
    "Direct-To-Film Transfer",
    "Vinyl Heat Press",
    "ASI/Promotional Products",
    "Retail/Fashion Brand",
    "Hobbyist Decorater",
    "Stickers/Banners/Wraps",
    "Other",
  ];

  const toggleCheckbox = (option) => {
    const updatedChecked = new Set(decoratingOptions);
    if (updatedChecked.has(option)) {
      updatedChecked.delete(option);
    } else {
      updatedChecked.add(option);
    }
    setDecoratingOptions(updatedChecked);
  };

  return (
    <div>
      <div className={styles.distributorTitle}>
        Decorating Style (select all that apply to your business)*
      </div>
      <div className={styles.distributorContainer}>
        {options.map((option, index) => (
          <div key={index} className={styles.option}>
            {decoratingOptions.has(option) ? (
              <CheckBoxIcon style={{ fontSize: 28 }} onClick={() => toggleCheckbox(option)} />
            ) : (
              <CheckBoxOutlineBlankIcon
                style={{ fontSize: 28 }}
                onClick={() => toggleCheckbox(option)}
              />
            )}
            <label onClick={() => toggleCheckbox(option)}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DecoratingInfo;
