import React from "react";
import styles from "./styles.module.scss";
import SideCartHeader from "./SideCartHeader";
import SideVendorCarts from "./SideVendorCarts";
import { useSelector } from "react-redux";
import { Typography, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { sendGetCartsPayload } from "features/sendGetCartsPayload";
import { useDataContext, useUserStateContext } from "App";
import { useDispatch } from "react-redux";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { debounce } from "lodash";

// Import the image
import PrintavoAdImage from "../../assets/PrintavoAd.png";

const RefreshCartsBox = () => {
  const { connectedVendorCodes } = useDataContext();
  const { userCreds } = useUserStateContext();
  const { sendJsonMessage } = useWebsocketContext();
  const dispatch = useDispatch();

  const handleRefreshCarts = () => {
    sendGetCartsPayload(
      userCreds?.cognitoID,
      sendJsonMessage,
      dispatch,
      connectedVendorCodes
    )
  };

  const debouncedHandleRefresh = debounce(handleRefreshCarts, 300);

  return (
    <IconButton
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        borderRadius: '4px',
        boxShadow: 'inset 0 -2px 4px -2px rgba(0, 0, 0, 0.1)',
        width: 'fit-content',
        border: '1px solid #d2d6da',
        cursor: 'pointer',
        "&:hover": {
          backgroundColor: "#f4f4f4",
        },
      }}
      className="bg-grayscaleWhite"
      onClick={debouncedHandleRefresh}
    >
      <RefreshIcon sx={{ fontSize: "1.33rem" }} className="mr-[8px] fill-blueblue" />
      <Typography variant="button" color="primary" className="normal-case text-blueblue text-base">
        Refresh Carts
      </Typography>
    </IconButton>
  );
};

const SideCart = () => {
  const vendorCarts = useSelector(state => state.carts?.carts);
  const hidePrices = useSelector((state) => state.user?.user?.hide_prices)

  const vendorTotals = {};
  for (const vendorCart of Object.entries(vendorCarts)) {
    const [vendorCode, vendorData] = vendorCart;
    if (!vendorData) {
      continue;
    }
    if (Object.entries(vendorData).length === 0) {
      continue;
    }

    const vendorCartItems = vendorData.items;
    if (!vendorCartItems) {
      continue;
    }


    let vendorSubtotal = 0.0;
    let vendorTotalItems = 0;
    for (const cartItem of vendorCartItems) {
      let itemTotalPrice = cartItem.totalPrice;
      if (typeof itemTotalPrice === "string") {
        itemTotalPrice = Number(itemTotalPrice.replace("$", ""));
      }
      vendorSubtotal += itemTotalPrice;
      vendorTotalItems += cartItem.quantity;
    }
    const newVendorTotals = {
      subtotal: vendorSubtotal,
      totalItems: vendorTotalItems,
    };
    vendorTotals[vendorCode] = newVendorTotals;
  }

  const handleClick = () => {
    window.open("https://www.inktavo.com/tools-resources/direct-to-film-pricing-calculator/?utm_source=DGI_ad&utm_medium=paid&utm_campaign=dtf_calculator", "_blank");
  };

  return (
    <div className={`${styles.sideCart}`}>
      {/* <Box
        border={1}
        borderRadius="5px"
        sx={{
          width: '238px',
          height: 120,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          transition: 'transform 0.2s ease',
          '&:hover': {
            transform: 'scale(1.04)',
          },
        }}
        onClick={handleClick}
      >
        <img src={PrintavoAdImage} alt="Printavo Ad" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </Box> */}
      <RefreshCartsBox />
      <SideCartHeader vendorTotals={vendorTotals} hidePrices={hidePrices} />
      <SideVendorCarts
        vendorCarts={vendorCarts}
        vendorTotals={vendorTotals}
        hidePrices={hidePrices}
      />
    </div>
  );
};

export default SideCart;
