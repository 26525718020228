import ABLogo from "../assets/ABLogo.png";
import CMLogo from "../assets/CMLogo.png";
import SMLogo from "../assets/SMLogo.png";
import SSLogo from "../assets/SSLogo.png";
import ASLogo from "../assets/ASLogo.png";
import ACCLogo from "../assets/ACCLogo.png";
import PGLogo from "../assets/PGLogo.png";
import ASCLogo from "../assets/ASCLogo.png";
import STALogo from "../assets/STALogo.png";
import SLCLogo from "../assets/SLCLogo.png";

export const vendorStyleKeys = [
  "sm_style",
  "ss_style",
  "ab_style",
  "cm_style",
  "acc_style",
  "pg_style",
  "as_style",
  "asc_style",
  "sta_style",
  "slc_style",
];

export const suggestVendorConnections = new Set(["sta", "asc", "slc"]);

export const switchWarehouseDisabledVendors = new Set(["as", "acc", "pg", "slc", "ss"]);

export const vendorsWithoutAttachedCutoffTimes = new Set(["sm", "asc", "slc"]);

export const checkoutDisabledVendorNames = new Set([
  "Augusta Sportswear",
  "Atlantic Coast Cotton",
  "PrintGear",
  "AS Colour",
  "Staton",
  "SLC Activewear",
  "Sanmar",
])

export const vendors = {
  ab_data: {
    name: "Alphabroder",
    search_filter: "Alphabroder",
    code: "ab",
    style_field: "ab_style",
    link_field: "ab_product_url",
    logo: ABLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.alphabroder.com/customer-registration"
  },
  cm_data: {
    name: "Carolina Made",
    search_filter: "Carolina Made",
    code: "cm",
    style_field: "cm_style",
    link_field: "cm_product_url",
    data_field: "cm_data",
    logo: CMLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.carolinamade.com/cgi-bin/live/wam_tmpl/customer_registration.p?site=CMD&layout=Base_b2b&page=customer_registration"
  },
  sm_data: {
    name: "Sanmar",
    search_filter: "Sanmar",
    code: "sm",
    u_key: "sm_username",
    p_key: "sm_password",
    style_field: "sm_style",
    link_field: "sm_product_url",
    data_field: "sm_data",
    logo: SMLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.sanmar.com/signup/customer"
  },
  ss_data: {
    name: "S&S Activewear",
    search_filter: "S&S",
    code: "ss",
    style_field: "ss_style",
    link_field: "ss_product_url",
    data_field: "ss_data",
    logo: SSLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.ssactivewear.com/myaccount/register"
  },
  as_data: {
    name: "Augusta Sportswear",
    search_filter: "Augusta Sportswear",
    code: "as",
    style_field: "as_style",
    data_field: "as_data",
    logo: ASLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.augustasportswear.com/New-Account"
  },
  acc_data: {
    name: "Atlantic Coast Cotton",
    search_filter: "ACC",
    code: "acc",
    style_field: "acc_style",
    data_field: "acc_data",
    logo: ACCLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.orderacc.com/cgi-bin/liveb2b/wam_tmpl/customer_registration.p?site=ACC&layout=Responsive&page=customer_registration"
  },
  pg_data: {
    name: "PrintGear",
    search_filter: "PrintGear",
    code: "pg",
    style_field: "pg_style",
    data_field: "pg_data",
    logo: PGLogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.printgear.com/signup"
  },
  asc_data: {
    name: "AS Colour",
    search_filter: "AS Colour",
    code: "asc",
    style_field: "asc_style",
    data_field: "asc_data",
    logo: ASCLogo,
    freeShippingMin: 500,
    account_creation_link: "https://www.ascolour.com/wholesale-form/?utm_source=dgi_apparel&utm_medium=website_referral&utm_campaign=create_account"
  },
  sta_data: {
    name: "Staton",
    search_filter: "Staton",
    code: "sta",
    style_field: "sta_style",
    data_field: "sta_data",
    logo: STALogo,
    freeShippingMin: 200,
    account_creation_link: "https://www.statononline.com/help_center"
  },
  slc_data: {
    name: "SLC Activewear",
    search_filter: "SLC",
    code: "slc",
    style_field: "slc_style",
    data_field: "slc_data",
    logo: SLCLogo,
    freeShippingMin: 200,
    account_creation_link: "https://slcactivewear.com/create-account/?utm_source=dgi_apparel"
  },

};

export default vendors;
