import { useState, useEffect } from "react";
import { usePagination } from "react-instantsearch";

import styles from "./styles.module.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation } from "react-router-dom";

function SequentialPageBtn({ btnType, refine, currentRefinement, displayBtn }) {
  const [newRefinement, setNewRefinement] = useState(null);

  useEffect(() => {
    setNewRefinement(() => {
      if (btnType === "next") {
        return currentRefinement + 1;
      } else if (btnType === "prev") {
        return currentRefinement - 1;
      }
    });
  }, [currentRefinement]);

  if (!displayBtn) {
    return null;
  }

  let renderBtnContent;
  if (btnType === "next") {
    renderBtnContent = (
      <>
        <span className="hidden sm:block">Next</span>
        <KeyboardArrowRightIcon className="text-charcoal" />
      </>
    );
  } else if (btnType === "prev") {
    renderBtnContent = (
      <>
        <KeyboardArrowLeftIcon className="text-charcoal" />
        <span className="hidden sm:block">Previous</span>
      </>
    );
  }

  return (
    <li
      className={`list-none sm:w-96 h-full flex justify-center ${styles.prevNextBtnBorder} ${styles.selectedPage}`}
      data-next-btn={Boolean(btnType === "next")}
      data-is-selected={false}
      data-is-page={false}
      onClick={(event) => {
        event.preventDefault();
        refine(newRefinement);
        window.scrollTo(0, 0);
      }}
    >
      <a style={{ color: "#111", textDecoration: "none" }} href="#">
        <div className="flex items-center justify-center w-full h-full px-12px">
          {renderBtnContent}
        </div>
      </a>
    </li>
  );
}

function PageShortcut({
  shortcutType,
  displayShortcut,
  refine,
  nbPages,
  showEllipsis,
}) {
  const [newRefinement, setNewRefinement] = useState(null);
  const [pageNumberDisplay, setPageNumberDisplay] = useState(null);

  useEffect(() => {
    setNewRefinement(() => {
      if (shortcutType === "first") {
        return 0;
      } else if (shortcutType === "last") {
        return nbPages - 1;
      }
    });
    setPageNumberDisplay(() => {
      if (shortcutType === "first") {
        return 1;
      } else if (shortcutType === "last") {
        return nbPages;
      }
    });
  }, [shortcutType, nbPages]);

  if (!displayShortcut) {
    return null;
  }

  return (
    <>
      {shortcutType === "last" && showEllipsis && (
        <li className="list-none flex items-center justify-center w-56 h-48">
          ...
        </li>
      )}
      <li
        className={`list-none flex items-center justify-center w-56 h-48 ${styles.selectedPage}`}
        data-is-selected={false}
        onClick={(event) => {
          event.preventDefault();
          refine(newRefinement);
          window.scrollTo(0, 0);
        }}
      >
        <a style={{ color: "#111", textDecoration: "none" }} href="#">
          {pageNumberDisplay}
        </a>
      </li>
      {shortcutType === "first" && showEllipsis && (
        <li className="list-none flex items-center justify-center w-56 h-48">
          ...
        </li>
      )}
    </>
  );
}

function CustomPagination(props) {
  const location = useLocation();
  const { pages, currentRefinement, nbPages, refine } = usePagination(props);
  const [displayPages, setDisplayPages] = useState([]);
  const [displayFirstPageShortcut, setDisplayFirstPageShortcut] =
    useState(false);
  const [showFirstPageShortcutEllipsis, setShowFirstPageShortcutEllipsis] =
    useState(true);
  const [displayLastPageShortcut, setDisplayLastPageShortcut] = useState(false);
  const [showLastPageShortcutEllipsis, setShowLastPageShortcutEllipsis] =
    useState(true);
  const [displayPrevPageBtn, setDisplayPrevPageBtn] = useState(false);
  const [displayNextPageBtn, setDisplayNextPageBtn] = useState(false);

  useEffect(() => {
    if (props?.windowWidth > 640) {
      setDisplayPages(pages);
    } else {
      setDisplayPages(pages.slice(0, 3));
    }
  }, [props.windowWidth, pages]);

  useEffect(() => {
    const firstPageInPages = displayPages.includes(0);
    const lastPageInPages = displayPages.includes(nbPages - 1);
    setDisplayFirstPageShortcut(!firstPageInPages);
    setDisplayLastPageShortcut(!lastPageInPages);
  }, [displayPages]);

  useEffect(() => {
    const secondPageInPages = displayPages.includes(1);
    const secondToLastPageInPages = displayPages.includes(nbPages - 2);
    setShowFirstPageShortcutEllipsis(!secondPageInPages);
    setShowLastPageShortcutEllipsis(!secondToLastPageInPages);
  }, [displayPages]);

  // Upon mounting, we want to make sure that the pagination results match
  // the search params in the url. 
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = Object.fromEntries(searchParams.entries());
    if (
      paramsObject["products[page]"] &&
      Number(paramsObject["products[page]"])-1 !== currentRefinement
    ) {
      refine(Number(paramsObject["products[page]"])-1);
    }
  }, [])

  useEffect(() => {
    if (currentRefinement === 0) {
      setDisplayPrevPageBtn(false);
    } else if (currentRefinement > 0) {
      setDisplayPrevPageBtn(true);
    }

    if (currentRefinement === nbPages - 1) {
      setDisplayNextPageBtn(false);
    } else if (currentRefinement < nbPages - 1) {
      setDisplayNextPageBtn(true);
    }
  }, [currentRefinement, nbPages]);

  return (
    <div className="max-w-full">
      <ul
        className={`flex justify-between items-center
            border border-solid border-standard rounded-soft
            h-48
            bg-white
            pl-0
            shadow-pagination
            text-sm`}
      >
        <SequentialPageBtn
          btnType="prev"
          refine={refine}
          currentRefinement={currentRefinement}
          nbPages={nbPages}
          displayBtn={displayPrevPageBtn}
        />
        <PageShortcut
          shortcutType="first"
          displayShortcut={displayFirstPageShortcut}
          refine={refine}
          showEllipsis={showFirstPageShortcutEllipsis}
        />
        {displayPages.map((page) => (
          <li
            key={page}
            className={`list-none flex items-center justify-center w-56 h-48 ${styles.selectedPage}`}
            data-is-selected={currentRefinement === page}
            onClick={(event) => {
              event.preventDefault();
              refine(page);
              window.scrollTo(0, 0);
            }}
          >
            <a style={{ textDecoration: "none" }} href="#">
              {page + 1}
            </a>
          </li>
        ))}
        <PageShortcut
          shortcutType="last"
          displayShortcut={displayLastPageShortcut}
          refine={refine}
          nbPages={nbPages}
          showEllipsis={showLastPageShortcutEllipsis}
        />
        <SequentialPageBtn
          btnType="next"
          refine={refine}
          currentRefinement={currentRefinement}
          displayBtn={displayNextPageBtn}
        />
      </ul>
    </div>
  );
}

export default CustomPagination;
